
/*
Purpose: Update Centre details
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
Updated on : 24/08/2023
updated by : KTH
*/
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Row, Col, Stack, Form, Button,Modal } from 'react-bootstrap';
import { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Select from 'react-select'
import axios from 'axios';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { FiEye, FiEyeOff } from "react-icons/fi"; // Assuming you've imported the eye icons
import { security_key, options_time_zone, options_countries, options_time_zone_identifier , BASE_URL, options_days, options_months} from "../global";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Table } from "react-bootstrap";

const EditAdmin = () => {

    
    const options_week_day = [
        { value: "Monday", label: "Monday"},
        { value: "Tuesday", label: "Tuesday"},
        { value: "Wednesday", label: "Wednesday"},
        { value: "Thusday", label: "Thusday"},
        { value: "Friday", label: "Friday"},
        { value: "Saturday", label: "Saturday"},
        { value: "Sunday", label: "Sunday"}
    ]
  
    const options_att = [
        { value: "Pending", label: "Pending"},
        { value: "Completed", label: "Completed"},
    ]


    const basicSchema = yup.object().shape({
            /*tbl_center*/
            cent_name: yup.string().required("Centre name is required.").min(2, "Minimum 2 characters is required.").max(100, "Maximum 100 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
            cent_emailid: yup.string().email("Please enter a valid email address").required("Email is required.").matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
                return value && value.includes('.');
              }),
           // cent_location: yup.string().required("Location is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
            // cent_weekday: yup.mixed().required("Week is required."),
            // cent_resetdate: yup.date().required("Rest date is required."),
           
           // cent_attance: '',

            /*tbl_center_auth*/
            // ca_login_id: yup.string().required("Username is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
            // ca_password: yup.string().min(5, "Minimum 5 characters is required.").matches(passwordRules).required("Password is required."),
            // ca_con_password: yup
            // .string()
            // .oneOf([yup.ref("ca_password"), null], "Password is not matching")
            // .required("Confirm password is required."), 

            /*tbl_incharge*/
            ci_first_name: yup.string().required("First name is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
            ci_surname: yup.string().required("Last Name is required.").min(1, "Minimum 1 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
            cent_timezone: yup.mixed().required("Timezone is required."),
            cent_timezone_identifier: yup.mixed().required("Timezone identifier is required."),
            cent_address: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("Address is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
            cent_country: yup.mixed().required("Country is required."),
            cent_city: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required(" City/Town is  required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
            cent_state: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("State is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
            cent_zip:  yup.string().min(4, "Minimum 4 characters is required.").max(8, "Maximum 8 characters is required.").required("Postcode is required.").matches(/^[a-zA-Z0-9\/-]+$/, "Allow only letters, numbers, - and /."),
            
            cent_comis_amt: yup.number().required("Percentage is required.").min(0, "Percentage must be greater than or equal to 0.").max(100, "Percentage must be less than or equal to 100"),

            paymentModes: yup.object().shape({
                paymentGateway: yup.array().test(
                    'at-least-one-payment-mode',
                    'At least one payment mode (Payment Gateway or Non-Payment Gateway) is required.',
                    function (paymentGateway) {
                        const { nonPaymentGateway } = this.parent; // access nonPaymentGateway
                        return paymentGateway.length > 0 || nonPaymentGateway.length > 0;
                    }
                ),
                nonPaymentGateway: yup.array(),
                // nonPaymentGateway: yup.array().test(
                //     'validate-otherPaymentModes',
                //     'If selected Others in Non-Payment Gateway, Other payment Modes must contain at least one data.',
                //     function (nonPaymentGateway) {
                //         const { otherPaymentModes } = this.parent;
                //         if (nonPaymentGateway.includes("D7D377070A05F8EF")) {
                //             return otherPaymentModes.length > 0;
                //         }
                //         return true;
                //     }
                // ),
                otherPaymentModes: yup.array(),
            }),
    });



  const [AdminData, setAdminData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isSubmittingPass, setIsSubmittingPass] = useState(false);


  const [showModal1, setShowModal1] = useState(false);
  const toggleModal1 = () => setShowModal1(!showModal1);
  
  const navigate = useNavigate();

  const handleSave = () => {
      navigate("/admin/admin-list");
  };

  const [passwordVisible, setPasswordVisible] = useState(false);
   
  
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [ConpasswordVisible, setConPasswordVisible] = useState(false);
 

  const toggleConPasswordVisibility = () => {
      setConPasswordVisible(!ConpasswordVisible);
  };


  
    //Api call start
    const [clickedValue, setClickedValue] = useState(1);
//  // Convert the stored date to YYYY-MM-DD format



  const [Id, setId] = useState('');
  //Get key value start


 
  const location = useLocation();
  useEffect(() => {

    const { pk_cent_key } = location.state || {};
    const extractedAdId = pk_cent_key;
    
    if (extractedAdId !=='' && extractedAdId !== undefined) {
      setId(extractedAdId);
      fetchData(extractedAdId);
      fetchPaymentData(extractedAdId);
      fetchOtherPaymentData(extractedAdId);
    }else {
        navigate("/admin/admin-list");
      }
  }, [location]);
 //Get key value end

//Api call start

    const fetchData = async (id) => {
        try {
            const response = await axios.post(BASE_URL+'API/api_index.php', {
                security_key: security_key,
                mode: "getSingleCenter_new",
                pk_cent_key: id
            });

            if (response.data.success === true) {
                if(response.data.data.length > 0){
                    setAdminData(response.data.data[0]);
                    setClickedValue(response.data.data[0]['sp_pay_freq'] || 1);
               
                }
            
            
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const [OnlinePaymentModes, setOnlinePaymentModes] = useState([]);
    const [nonPaymentModes, setNonPaymentModes] = useState([]);
    const [otherModes, setOtherModes] = useState([]);


//Api call End

    const formRef = useRef(null);

    const onSubmit = async (formValues) => {
        // Disable the submit button and reset alerts
        setIsSubmitting(true);
       
        try {
            const response = await axios.post(BASE_URL+"API/api_index.php", {
                security_key: security_key,
                mode: "updateCenter_new",
                ...formValues,
               
            });

            if (response.data.success === true) {
                // Reset the form
                //formRef.current.reset();
                if(response.data.data === 1){
                    toast.success("Success! Your form was submitted", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    // After 3 seconds, redirect to another page
                    setTimeout(function () {
                        navigate("/admin/admin-list");
                        setIsSubmitting(false); // Re-enable the submit button
                    }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                  
                }else if(response.data.data === 2){
                    toast.error("Centre Name is not available", {
                        autoClose: 3000, // 3 seconds in this example
                    });
                    setTimeout(function () {
                        setIsSubmitting(false); // Re-enable the submit button

                    });
                }else if (response.data.data === 4) {
                    toast.error("Email already exists", {
                        autoClose: 3000, // 3 seconds in this example
                    });
                    setTimeout(function () {
                        setIsSubmitting(false); // Re-enable the submit button

                    });
                }else{
                    toast.error("Failure! Unable to update centre. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setTimeout(function () {
                        setIsSubmitting(false); // Re-enable the submit button

                    });
                }
                
            } else {

                toast.error("Failure! Unable to update centre. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setTimeout(function () {
                    setIsSubmitting(false); // Re-enable the submit button

                });
                
            }
        } catch (error) {
            toast.error("Failure! Unable to update centre. Try again later", {
                autoClose: 3000, // 3 seconds in this example
            });

            setTimeout(function () {
                setIsSubmitting(false); // Re-enable the submit button

            });
            
        } finally {
            setTimeout(function () {
                setIsSubmitting(false); // Re-enable the submit button

            });
            
        }
    };

    
    const formik = useFormik({
        initialValues: {
                /*tbl_center*/
            cent_name: AdminData ? AdminData.cent_name : '',
            cent_emailid: AdminData ? AdminData.cent_emailid : '',
            cent_location: AdminData ? AdminData.cent_location : '',
            cent_weekday: AdminData ? AdminData.cent_weekday : '',
            cent_resetdate: AdminData ? AdminData.cent_resetdate : '',
            cent_state: AdminData ? AdminData.cent_state : '',
            cent_attance: '',

            cent_address:  AdminData ? AdminData.cent_address : '',
            cent_city:  AdminData ? AdminData.cent_city : '',
            cent_country:  AdminData ? AdminData.cent_country : '',
            cent_zip:  AdminData ? AdminData.cent_zip : '',
            cent_timezone:  AdminData ? AdminData.cent_timezone : '',
            cent_timezone_identifier:  AdminData ? AdminData.cent_timezone_identifier : '',

            // /*tbl_center_auth*/
            // ca_login_id: AdminData ? AdminData.ca_login_id : '',
            // ca_password: AdminData ? AdminData.ca_direct_password : '',
            // ca_con_password: AdminData ? AdminData.ca_direct_password : '', 

            /*tbl_incharge*/
            ci_first_name: AdminData ? AdminData.ci_first_name : '',
            ci_surname: AdminData ? AdminData.ci_surname : '',
            cent_comis_amt: AdminData ? AdminData.cent_comis_amt : '',
           
            /*Common*/
            updated_by: sessionStorage.getItem('key'),
            pk_cent_key: AdminData ? AdminData.pk_cent_key : '',
            pk_cent_id: AdminData ? AdminData.pk_cent_id : '',
            pk_ci_id: AdminData ? AdminData.pk_ci_id : '',


            stp_product_name: AdminData ? AdminData.stp_product_name : '',
                stp_price: AdminData ? AdminData.stp_price : '',
                stp_price_id: AdminData ? AdminData.stp_price_id : '',
                stp_price_meter: AdminData ? AdminData.stp_price_meter : '',

                sp_start_ofDay: AdminData ? AdminData.sp_pay_freq != '' && AdminData.sp_pay_freq != null && AdminData.sp_pay_freq==1 ? AdminData.sp_start_ofDay : '' : '',
                sp_start_ofDay_mon: AdminData ? AdminData.sp_pay_freq != '' && AdminData.sp_pay_freq != null && AdminData.sp_pay_freq==2 ? AdminData.sp_start_ofDay : '' : '',
                sa_publishable_key: AdminData ? AdminData.sa_publishable_key : '',
                sa_secret_key: AdminData ? AdminData.sa_secret_key : '',

                paymentModes: {
                    paymentGateway: OnlinePaymentModes.filter((Mode) => Mode.checked).map((Mode) => Mode.pk_pm_key) || [],
                    nonPaymentGateway: nonPaymentModes.filter((Mode) => Mode.checked).map((Mode) => Mode.pk_pm_key) || [],
                    otherPaymentModes: otherModes || [],
                },
            
               
            },
        enableReinitialize: true,
        validationSchema: basicSchema,
        onSubmit,
    });
    


    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;



    const fetchPaymentData = async (id) => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getPaymentModeByCenter",
                fk_cent_key: id
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    //Filter Online Mode
                    const filteredOnlineArray = response.data.data.filter(
                        item => parseInt(item.pm_type) === 1
                    ).map(item => ({
                        ...item,
                        checked: parseInt(item.assignment_status) > 0
                    }));
                    if (filteredOnlineArray.length > 0) {
                        setOnlinePaymentModes(filteredOnlineArray);
                       
                        // formik.setFieldValue(
                        //     'paymentModes.paymentGateway',
                        //     filteredOnlineArray.filter((Mode) => Mode.checked).map((Mode) => Mode.pk_pm_key)
                        // );
                        
                      
                     
                    } else {
                        setOnlinePaymentModes([]);
                    }

                    //Filter Offline Mode
                    const filteredOfflineArray = response.data.data.filter(
                        item => parseInt(item.pm_type) === 2
                    ).map(item => ({
                        ...item,
                        checked: parseInt(item.assignment_status) > 0
                    }));
                    if (filteredOfflineArray.length > 0) {
                        setNonPaymentModes(filteredOfflineArray);
                        // formik.setFieldValue(
                        //     'paymentModes.nonPaymentGateway',
                        //     filteredOfflineArray.filter((Mode) => Mode.checked).map((Mode) => Mode.pk_pm_key)
                        // );
                       
                        //formik.validateField('paymentModes');
                    } else {
                        setNonPaymentModes([]);
                    }

                    

                }
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchOtherPaymentData = async (id) => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getOtherPaymentModeByCenter",
                fk_cent_key: id
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    // Assuming the API response is an array of objects with 'id' and 'value' properties
                        const formattedData = response.data.data.map(option => ({
                            copm_name: option.copm_name,
                            pk_copm_key: option.pk_copm_key
                        }));
                        setOtherModes(formattedData);
                        // formik.setFieldValue(
                        //     'paymentModes.otherPaymentModes',
                        //     formattedData
                        // );

                }else{
                    setOtherModes([]);
                }
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    

    const handleUsernameBlur = useCallback(async () => {
        const username = formik.values.ca_login_id;
        if (username) {
            try {
                const response = await axios.post(BASE_URL+"API/api_index.php", {
                    userID: username,
                    security_key: security_key,
                    pk_cent_key: AdminData ? AdminData.pk_cent_key : '',
                    mode: "checkCenterUserId",
                });

                if (response.data.success == true) {
                    formik.setFieldTouched("ca_login_id", true, false);
                    formik.setFieldError("ca_login_id", "Username is not available");
                } else {
                    formik.setFieldTouched("ca_login_id", true, false);
                    formik.setFieldError("ca_login_id", null);
                }
            } catch (error) {
                console.error("Error checking username:", error);
                formik.setFieldError("ca_login_id", null);
            }
        }
    }, [formik.values.ca_login_id]);


    
    const handleUsernameBlur1 = useCallback(async () => {
        const cent_name = formik.values.cent_name;
        if (cent_name) {
            try {
                const response = await axios.post(BASE_URL+"API/api_index.php", {
                    cent_name: cent_name,
                    pk_cent_key: AdminData ? AdminData.pk_cent_key : '',
                    security_key: security_key,
                    mode: "checkCenterName",
                });

                if (response.data.success === true) {
                    formik.setFieldTouched("cent_name", true, false);
                    formik.setFieldError("cent_name", "Centre Name is not available");
                } else {
                    formik.setFieldTouched("cent_name", true, false);
                    formik.setFieldError("cent_name", null);
                }
            } catch (error) {
                console.error("Error checking username:", error);
                formik.setFieldError("cent_name", null);
            }
        }
    }, [formik.values.cent_name]);

    const [hide_some_filed, setFideSomeFiled] = useState(false);
    


  //  const passwordRules = /^(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const validationSchema1 = yup.object().shape({
    ca_login_id: yup.string().required("Username is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
    ca_password: yup.string().min(5, "Minimum 5 characters is required.").required("Password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
    ca_con_password: yup
        .string()
        .oneOf([yup.ref("ca_password"), null], "Password is not matching")
        .required("Confirm password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
    
  });
    
    const initialValues1 = {
       
        pk_cent_key: AdminData ? AdminData.pk_cent_key : '',
        pk_cent_id: AdminData ? AdminData.pk_cent_id : '',

        /*tbl_center_auth*/
        ca_login_id: AdminData ? AdminData.ca_login_id : '',
        ca_password: AdminData ? AdminData.ca_direct_password : '',
        ca_con_password: '',
        pk_ci_id: AdminData ? AdminData.pk_ci_id : '',
        updated_by: sessionStorage.getItem('key'),
  };


  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,
    enableReinitialize: true,
    onSubmit: async (values) => {
        setIsSubmittingPass(true);


      try {
        const response = await axios.post(BASE_URL+"API/api_index.php", {
          security_key: security_key,
          mode: "updateCenterAuth",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Manage Login.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setTimeout(function () {
                navigate("/admin/admin-list");
                setIsSubmittingPass(false); // Re-enable the submit button
            }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
            formik1.resetForm();
             toggleModal1();
            


           
          }else if(response.data.data === 2){

            toast.error("Username is not available", {
                autoClose: 3000, // 3 seconds in this example
            });

            setTimeout(function () {
                setIsSubmittingPass(false); // Re-enable the submit button
            });

        } else {
            toast.error("Failure! Unable to update manage login. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setTimeout(function () {
                setIsSubmittingPass(false); // Re-enable the submit button
            });
          }

        } else {

          toast.error("Failure! Unable to update manage login. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setTimeout(function () {
            setIsSubmittingPass(false); // Re-enable the submit button
        });

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update manage login. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setTimeout(function () {
            setIsSubmittingPass(false); // Re-enable the submit button
        });

      } finally {
        setTimeout(function () {
            setIsSubmittingPass(false); // Re-enable the submit button
        });

      }

    },
  });

  const handleUsernameBlur2 = useCallback(async () => {
    const username = formik1.values.ca_login_id;
    if (username) {
        try {
            const response = await axios.post(BASE_URL+"API/api_index.php", {
                userID: username,
                pk_cent_key: AdminData ? AdminData.pk_cent_key : '',
                security_key: security_key,
                mode: "checkCenterUserId",
            });

            if (response.data.success === true) {
                formik1.setFieldTouched("ca_login_id", true, false);
                formik1.setFieldError("ca_login_id", "Username is not available");
            } else {
                formik1.setFieldTouched("ca_login_id", true, false);
                formik1.setFieldError("ca_login_id", null);
            }
        } catch (error) {
            console.error("Error checking username:", error);
            formik1.setFieldError("ca_login_id", null);
        }
    }
}, [formik1.values.ca_login_id]);



    //payment mode
   
     
      const [isAdding, setIsAdding] = useState(false);
      const [newMode, setNewMode] = useState("");
      const [editMode, setEditMode] = useState("");
      const [isEditing, setIsEditing] = useState('');

      const handleCheckboxChange = (pm_name, type, checked) => {
        if (type === 1) {
            // Online-Payment Gateway
            const updatedModes = OnlinePaymentModes.map((Mode) =>
                Mode.pm_name === pm_name ? { ...Mode, checked } : Mode
            );
            setOnlinePaymentModes(updatedModes);
            formik.setFieldValue(
                'paymentModes.paymentGateway',
                updatedModes.filter((Mode) => Mode.checked).map((Mode) => Mode.pk_pm_key)
            );
            formik.validateField('paymentModes');
        } else if (type === 2) {
            // Non-Payment Gateway
            const updatedModes = nonPaymentModes.map((Mode) =>
                Mode.pm_name === pm_name ? { ...Mode, checked } : Mode
            );
            setNonPaymentModes(updatedModes);
            formik.setFieldValue(
                'paymentModes.nonPaymentGateway',
                updatedModes.filter((Mode) => Mode.checked).map((Mode) => Mode.pk_pm_key)
            );
            formik.validateField('paymentModes');
        }
    };
   
    function generateHexKey(keyLength) {
        const bytes = new Uint8Array(keyLength / 2);
        window.crypto.getRandomValues(bytes); // Fill bytes with random values
        let key = Array.from(bytes).map(byte => byte.toString(16).padStart(2, '0')).join('');
        key = key.toUpperCase(); // Convert to uppercase
        return key;
      }
    const handleAddMode = async () => {
        if (newMode) {
            const key = generateHexKey(16);
            const newModeObj = { copm_name: newMode , pk_copm_key: key};
            try {
                // Make an API request here with the add object
                const response_add = await axios.post(BASE_URL + 'API/api_index.php', {
                    security_key: security_key,
                    mode: "addOtherPaymentModeByCenter",
                    fk_cent_key: Id,
                    pk_copm_key: key,
                    fk_pm_key: "D7D377070A05F8EF",
                    copm_name: newMode,
                });

                // Handle the API response here
                if (response_add.data.data && parseInt(response_add.data.data) === 1) {
                
                    setOtherModes([...otherModes, newModeObj]);
                    formik.setFieldValue('paymentModes.otherPaymentModes', [
                        ...formik.values.paymentModes.otherPaymentModes,
                        newModeObj,
                    ]);
                } else {

                }

            } catch (error) {
              
            }
             
          
            setNewMode('');
            setIsAdding(false);
        }
    };

    const handleEditMode = (index) => {
        setEditMode(otherModes[index].copm_name);
        setIsEditing(index);
    };

    const handleSaveEdit = async (index) => {
        const updatedModes = [...otherModes];
        const old_pk_copm_key = updatedModes[index].pk_copm_key;
        const newModeObj = { copm_name: editMode, pk_copm_key: old_pk_copm_key};
        updatedModes[index] = newModeObj;
        try {
            // Make an API request here with the add object
            const response_edit = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "updateOtherPaymentModeByCenter",
                fk_cent_key: Id,
                pk_copm_key: old_pk_copm_key,
                copm_name: editMode,
            });

            // Handle the API response here
            if (response_edit.data.data && parseInt(response_edit.data.data) === 1) {
            
                setOtherModes(updatedModes);
                formik.setFieldValue('paymentModes.otherPaymentModes', updatedModes);
            } else {

            }

        } catch (error) {
          
        }

        
        setIsEditing('');
    };

    const handleDeleteMode = async (pk_copm_key) => {
        try {
            // Make an API request here with the add object
            const response_delete = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "deleteOtherPaymentModeByCenter",
                fk_cent_key: Id,
                pk_copm_key: pk_copm_key,
            });

            // Handle the API response here
            if (response_delete.data.data && parseInt(response_delete.data.data) === 1) {
            
                const updatedModes = otherModes.filter((Mode) => Mode.pk_copm_key !== pk_copm_key);
                setOtherModes(updatedModes);
                formik.setFieldValue('paymentModes.otherPaymentModes', updatedModes);
            } else {

            }

        } catch (error) {
          
        }
       
    };


    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) { const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;
          
            const errorFieldElement = document.getElementById(firstErrorField);
          
            if (errorFieldElement) {
               errorFieldElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
            } else {
             // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
         //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
      }, [formik]);
     
  return (
    <>
    {/* React alert */}
     <ToastContainer position="top-center" />
    <Modal show={showModal1} onHide={toggleModal1} size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Header closeButton>
            <Modal.Title>Manage Login</Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik1.handleSubmit}>
            <Modal.Body>

              {/* --------------Date--------------- */}
             
            <div className='section-heading border-bottom pb-3 mb-3'>
                <h6 className='mb-0'>Centre App Login</h6>
            </div>
              <Row>


                <Col lg="4" md="6" sm="12" className='position-relative'>
                    <h6 className="form-lable required-field">Username</h6>
                    <Form.Control type="text"
                        name="ca_login_id"
                        autoComplete="off"

                        value={formik1.values.ca_login_id}
                        onChange={formik1.handleChange}
                        // onBlur={handleBlur}
                        id="ca_login_id"
                        onBlur={formik1.handleBlur}
                        // onBlur={handleUsernameBlur2}
                        className={formik1.errors.ca_login_id && formik1.touched.ca_login_id ? "input-error mb-0" : "mb-0"}
                        />
                        {formik1.errors.ca_login_id && formik1.touched.ca_login_id && <span className="error">{formik1.errors.ca_login_id}</span>}
                </Col>
                                            
            {/* --------------Student Password--------------- */}
            
                <Col lg="4" md="6" sm="12" className='position-relative'>
                    <h6 className="form-lable required-field">Password</h6>
                    <Form.Control type={passwordVisible ? "text" : "password"}
                        autoComplete="new-password"
                        value={formik1.values.ca_password}
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        id="ca_password"
                        className={formik1.errors.ca_password && formik1.touched.ca_password ? "input-error mb-0" : "mb-0"}
                        />
                        <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="password-toggle-button pswicon" title="show / hide"
                        >
                            {passwordVisible ? <FiEye /> : <FiEyeOff />}

                        </button>
                    {formik1.errors.ca_password && formik1.touched.ca_password && <span className="error">{formik1.errors.ca_password}</span>}
                </Col>
                            

            {/* --------------Student Confirm Password--------------- */}
            
                <Col lg="4" md="6" sm="12" className='position-relative'>
                    <h6 className="form-lable required-field">Confirm Password</h6>
                    <Form.Control type={ConpasswordVisible ? "text" : "password"}
                        value={formik1.values.ca_con_password}
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        id="ca_con_password"
                        className={formik1.errors.ca_con_password && formik1.touched.ca_con_password ? "input-error mb-0" : "mb-0"}
                        />
                        <button
                            type="button"
                            onClick={toggleConPasswordVisibility}
                            className="password-toggle-button pswicon" title="show / hide"
                        >
                            {ConpasswordVisible ? <FiEye /> : <FiEyeOff />}

                        </button>
                        {formik1.errors.ca_con_password && formik1.touched.ca_con_password && <span className="error">{formik1.errors.ca_con_password}</span>}
                </Col>            
                      
              </Row>
           


            </Modal.Body>
            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={toggleModal1}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingPass}>Submit</Button>

              </div>

            </Modal.Footer>
          </Form>
        </Modal>
        <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>

            <div className="">
                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className='page-title'>  Centre Management </h5>
                        <div role="presentation" >
                            <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                            <NavLink underline="hover" color="inherit" to='/admin/admin-list'> Home</NavLink>


                                <Typography color="text.primary">Centre Management</Typography>
                            </Breadcrumbs>
                        </div>
                    </div>
                    <div className='d-flex gap-2'>
                    {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                        <div className='d-flex justify-content-end gap-2'>
                            <div >
                            <Button variant="contained"  className="primary-filled" style={{ fontSize: '12px' }} onClick={() => toggleModal1()} > Manage Login</Button>
                                {/* <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1" className="ps-0 rounded-10 primary-filled btn-action text-light">
                                    <Dropdown.Item onClick={() =>  toggleModal1()}>  Manage Login
                                    </Dropdown.Item>
                                   
                                    
                                </DropdownButton> */}
                            </div>
                        </div>
                    </div>
                </div>

                
                    
                {/* --------------Student Particulars--------------- */}
                <Stack>
                    <div className="my-card p-4 bg-white">
                        <div className='section-heading border-bottom pb-3 mb-3'>
                            <h6 className='mb-0'>Edit Owner/Centre </h6>
                        </div>
                        <Row>
                        
                            {/* --------------Centre Name--------------- */}
                           
                            <Col lg="4" md="6" sm="12" className='position-relative'>

                                <h6 className="form-lable required-field">Centre Name</h6>
                                <Form.Control type="text" 
                                 name="cent_name" 
                                value={values.cent_name}
                                onChange={handleChange}
                                onBlur={handleUsernameBlur1}
                                id="cent_name"
                                className={errors.cent_name && touched.cent_name ? "input-error" : ""}
                                 />
                                {errors.cent_name && touched.cent_name && <p className="error">{errors.cent_name}</p>}
                            </Col>
                            
                            {/* -------------- First Name--------------- */}
                            
                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">First Name</h6>
                                <Form.Control 
                                type="text"   
                                value={values.ci_first_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="ci_first_name"
                                className={errors.ci_first_name && touched.ci_first_name ? "input-error" : ""}
                                 />
                                {errors.ci_first_name && touched.ci_first_name && <p className="error">{errors.ci_first_name}</p>}
                            </Col>
                                            
                            {/* --------------Last Name--------------- */}        
                            
                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Last Name</h6>
                                <Form.Control type="text" 
                                value={values.ci_surname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="ci_surname"
                                className={errors.ci_surname && touched.ci_surname ? "input-error" : ""} 
                                 />
                                {errors.ci_surname && touched.ci_surname && <p className="error">{errors.ci_surname}</p>}
                            </Col>
                                            

                        {/* --------------user name--------------- */}   
                        
                            {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Login Username</h6>
                                <Form.Control type="text" 
                                    value={values.ca_login_id}
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                    id="ca_login_id"
                                    onBlur={handleUsernameBlur}
                                    className={errors.ca_login_id && touched.ca_login_id ? "input-error" : ""}   
                                     />
                                    {errors.ca_login_id && touched.ca_login_id && <p className="error">{errors.ca_login_id}</p>}
                            </Col> */}
                                            
                        {/* ------------ Email--------------- */}   
                    
                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Email</h6>
                                <Form.Control type="text" 
                                    value={values.cent_emailid}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="cent_emailid"
                                    className={errors.cent_emailid && touched.cent_emailid ? "input-error" : "mb-0"} 
                                     />
                                    {errors.cent_emailid && touched.cent_emailid && <p className="error">{errors.cent_emailid}</p>}
                            </Col>
                                            

                        {/* -------------- Password--------------- */}         
                        
                            {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Password</h6>
                                <Form.Control type="password"  
                                value={values.ca_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="ca_password"
                                className={errors.ca_password && touched.ca_password ? "input-error" : ""}   
                                 />
                                {errors.ca_password && touched.ca_password && <p className="error">{errors.ca_password}</p>}
                            </Col> */}
                                        
                        {/* --------------Confirm Password--------------- */}         
                        
                            {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Confirm Password</h6>
                                <Form.Control type="password" 
                                value={values.ca_con_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="ca_con_password"
                                className={errors.ca_con_password && touched.ca_con_password ? "input-error" : ""}    
                                 />
                                {errors.ca_con_password && touched.ca_con_password && <p className="error">{errors.ca_con_password}</p>}
                            </Col> */}
                                        

                        {/* --------------Student School--------------- */}
                        
                            {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Location</h6>
                                <Form.Control type="text"  
                                value={values.cent_location}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="cent_location"
                                className={errors.cent_location && touched.cent_location ? "input-error" : "mb-0"}  
                                 />
                                {errors.cent_location && touched.cent_location && <p className="error">{errors.cent_location}</p>}
                            </Col> */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">License Fee(%)</h6>
                                <Form.Control
                                    type="number"
                                    min={1}
                                    max={100}
                                    value={values.cent_comis_amt}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="cent_comis_amt"
                                    className={errors.cent_comis_amt && touched.cent_comis_amt ? "input-error" : "mb-0"}
                                />
                                {errors.cent_comis_amt && touched.cent_comis_amt && <p className="error">{errors.cent_comis_amt}</p>}
                            </Col>
                                            
                        </Row>                            
                        
                    </div>
                </Stack>
                           
                {/* --------------Student Particulars End--------------- */}


            {/* --------------Student Particulars End--------------- */}
                <Stack className='mt-3'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Contact Details</h6>
                            </div>
                            <Row>

                                 {/* --------------Contact Address1--------------- */}
                                 <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Address</h6>
                                    <Form.Control
                                       as="textarea" rows={3}
                                        
                                        value={values.cent_address}
                                        id="cent_address"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.cent_address && touched.cent_address ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.cent_address && touched.cent_address && <p className="error">{errors.cent_address}</p>}

                                </Col>
                                {/* --------------Student city--------------- */}
                               
                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable required-field">City/Town</h6>
                                    <Form.Control type="text" 
                                       value={values.cent_city}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       id="cent_city"
                                       className={errors.cent_city && touched.cent_city ? "input-error" : ""}
                                        />
                                         {errors.cent_city && touched.cent_city && <p className="error">{errors.cent_city}</p>}
                                </Col>
                            
                            {/* --------------Student state--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable required-field">County/Region/State</h6>
                                    <Form.Control type="text"  
                                     value={values.cent_state}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="cent_state"
                                     className={errors.cent_state && touched.cent_state ? "input-error" : ""}
                                     />
                                     {errors.cent_state && touched.cent_state && <p className="error">{errors.cent_state}</p>}
                                </Col>
                            
                             {/* --------------Student Country--------------- */}

                               
                             <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable required-field">Country</h6>
                                     <Select menuPlacement="auto"  options={options_countries}
                                        name="cent_country"
                                        value={options_countries.find(option => option.value && values.cent_country && option.value.toString() === values.cent_country.toString())}
                                        id="cent_country"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "cent_country",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cent_country: true });
                                        }}
                                        className={errors.cent_country && touched.cent_country ? "input-error" : ""}
                                    />
                                    {errors.cent_country && touched.cent_country && <p className="error">{errors.cent_country}</p>}
                                </Col>

                            {/* --------------Student Postal Code--------------- */}
                     
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Postal Code</h6>
                                    <Form.Control type="text"  
                                        value={values.cent_zip}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       id="cent_zip"
                                       className={errors.cent_zip && touched.cent_zip ? "input-error" : "mb-0"} 
                                       />
                                    {errors.cent_zip && touched.cent_zip && <p className="error">{errors.cent_zip}</p>}
                                </Col>

                                 {/* --------------Student Timezone--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Timezone</h6>
                                     <Select menuPlacement="auto"  options={options_time_zone}
                                        name="cent_timezone"
                                        value={options_time_zone.find(option => option.value && values.cent_timezone && option.value.toString() === values.cent_timezone.toString())}
                                        id="cent_timezone"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "cent_timezone",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cent_timezone: true });
                                        }}
                                        className={errors.cent_timezone && touched.cent_timezone ? "input-error" : "mb-0"}
                                    />
                                    {errors.cent_timezone && touched.cent_timezone && <span className="error">{errors.cent_timezone}</span>}
                                </Col>


                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Timezone Identifier</h6>
                                    <Select menuPlacement="auto" options={options_time_zone_identifier}
                                        name="cent_timezone_identifier"
                                        value={options_time_zone_identifier.find(option => option.value && values.cent_timezone_identifier && option.value.toString() === values.cent_timezone_identifier.toString())}
                                        id="cent_timezone_identifier"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "cent_timezone_identifier",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cent_timezone_identifier: true });
                                        }}
                                        className={errors.cent_timezone_identifier && touched.cent_timezone_identifier ? "input-error" : ""}
                                    />
                                    {errors.cent_timezone_identifier && touched.cent_timezone_identifier && <span className="error">{errors.cent_timezone_identifier}</span>}
                                </Col>

                            </Row>
                        </div>
                    </Stack>


                {/* --------------Payment Details--------------- */}
                <Stack className='mt-3' style={{ display:'none'}}>
                    <div className="my-card p-4 bg-white">
                        <div className='section-heading border-bottom pb-3 mb-3'>
                            <h6 className='mb-0'>Defaults </h6>
                        </div>
            
                            <Row>
                            {/* ------------ Week day--------------- */}   
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Week Day</h6>
                                     <Select menuPlacement="auto"  options={options_week_day}  
                                        value={options_week_day.find(option => option.value === values.cent_weekday)}
                                        name="cent_weekday"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "cent_weekday",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        id="cent_weekday"
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cent_weekday: true });
                                        }}
                                        className={errors.cent_weekday && touched.cent_weekday ? "input-error" : ""}
                                        />
                                        {errors.cent_weekday && touched.cent_weekday && <p className="error">{errors.cent_weekday}</p>}
                                </Col>
                                                                

                            {/* ------------ My point reset date day--------------- */}   
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">My point reset date</h6>
                                <Form.Control type="date"  
                                    value={values.cent_resetdate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="cent_resetdate"
                                    className={errors.cent_resetdate && touched.cent_resetdate ? "input-error" : ""}  
                                     
                                />
                                {errors.cent_resetdate && touched.cent_resetdate && <p className="error">{errors.cent_resetdate}</p>}
                                </Col>
                                                                
                            {/* ------------ My point reset date day--------------- */}   
                            
                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">County/Region/State</h6>
                                    <Form.Control type="text" 
                                        value={values.cent_state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_state"
                                        className={errors.cent_state && touched.cent_state ? "input-error" : ""}   
                                         
                                        />
                                    {errors.cent_state && touched.cent_state && <p className="error">{errors.cent_state}</p>}
                                </Col>
                                                                 */}
                                                            
                            {/* ------------ Process Attendence- default pending to--------------- */} 
                            {hide_some_filed && (    
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Process Attendence - Default pending to</h6>
                                     <Select menuPlacement="auto"  options={options_att} id="formstate" />
                                </Col>
                            
                            )}

                            {/* --------------Select Day of Month--------------- */}         
                            
                                
                             
                                <Col sm="12">
                                    <Col xs={12} md={12} className="text-end">
                                        <Button variant="contained" className="primary-filled mt-3">
                                            Connect Strip Account
                                        </Button>
                                    </Col>
                                </Col>                   
                                               
                             
                            </Row>
                                
                        </div>
                    </Stack>
                           
                {/* --------------Payment Details End--------------- */}


                <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3 d-flex justify-content-between'>
                                <h6 className='mb-0'>Stripe API Setting {['top'].map((placement) => (
                        <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                            <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                             Note: Please do not move one Stripe account to another because of student subscriptions. If you want to move, you will need to restart the student payment with a verified account.
                            </Tooltip>
                        }
                        >
                            <Button variant="secondary" className="no-bdr">
                                <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                            </Button>
                        </OverlayTrigger>
                        
                    ))}</h6>

                       
                            </div>
                            <Row>

                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Publishable key</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.sa_publishable_key}
                                        //onChange={handleChange}
                                        //onBlur={handleBlur}
                                        id="sa_publishable_key"
                                        disabled={true}
                                        className={errors.sa_publishable_key && touched.sa_publishable_key ? "input-error control_textarea" : "control_textarea"}
                                    />
                                    {errors.sa_publishable_key && touched.sa_publishable_key && <p className="error">{errors.sa_publishable_key}</p>}
                                    
                                </Col>

                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Secret key</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.sa_secret_key}
                                        //onChange={handleChange}
                                        //onBlur={handleBlur}
                                        disabled={true}
                                        id="sa_secret_key"
                                        className={errors.sa_secret_key && touched.sa_secret_key ? "input-error control_textarea" : "control_textarea"}
                                    />
                                    {errors.sa_secret_key && touched.sa_secret_key && <p className="error">{errors.sa_secret_key}</p>}
                                </Col>
                                
                                

                            </Row>
                        </div>
                    </Stack>
                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Fees </h6>
                            </div>
                            <Row>
                                {/* --------------Student First Name--------------- */}

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Product Name</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.stp_product_name}
                                        // onChange={handleChange}
                                        // onBlur={handleBlur}
                                        disabled={true}
                                        id="stp_product_name"
                                        className={errors.stp_product_name && touched.stp_product_name ? "input-error" : ""}
                                    />
                                    {errors.stp_product_name && touched.stp_product_name && <p className="error">{errors.stp_product_name}</p>}
                                </Col>
                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Fees</h6>
                                    <Form.Control
                                        type="number"
                                        step="0.001"
                                        min={0}
                                        value={values.stp_price}
                                        // onChange={handleChange}
                                        // onBlur={handleBlur}
                                        disabled={true}
                                        id="stp_price"
                                        className={errors.stp_price && touched.stp_price ? "input-error" : ""}
                                    />
                                    {errors.stp_price && touched.stp_price && <p className="error">{errors.stp_price}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Stripe Price ID</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.stp_price_id}
                                        // onChange={handleChange}
                                        // onBlur={handleBlur}
                                        disabled={true}
                                        id="stp_price_id"
                                        className={errors.stp_price_id && touched.stp_price_id ? "input-error" : ""}
                                    />
                                    {errors.stp_price_id && touched.stp_price_id && <p className="error">{errors.stp_price_id}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Stripe Price Meter Name</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.stp_price_meter}
                                        // onChange={handleChange}
                                        // onBlur={handleBlur}
                                        disabled={true}
                                        id="stp_price_meter"
                                        className={errors.stp_price_meter && touched.stp_price_meter ? "input-error" : ""}
                                    />
                                    {errors.stp_price_meter && touched.stp_price_meter && <p className="error">{errors.stp_price_meter}</p>}
                                </Col>
                            </Row>
                        </div>
                    </Stack>
                     {/* --------------Payment Details--------------- */}
                     <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Payment Frequency  {['top'].map((placement) => (
                        <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                            <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                             Note: Please select the price based on whether it is weekly, monthly, or others. This is because Stripe verifies the logic for the 'Billing Period' in your Stripe account. If it is incorrect, the payment will not be verified.
                            </Tooltip>
                        }
                        >
                            <Button variant="secondary" className="no-bdr">
                                <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                            </Button>
                        </OverlayTrigger>
                        
                    ))}</h6>
                            </div>
                            {/* <Tab.Container defaultActiveKey={StudentData.sp_pay_type === '2' ? 'second' : 'first'}> */}
                            <Tab.Container defaultActiveKey={"first"} activeKey={clickedValue}  onSelect={(k) => setClickedValue(k)} >
                                <Row>
                                    <Col sm={12}>
                                        <Nav variant="pills" className="flex-row justify-content-center mb-3 text-dark outside mx-auto">
                                            <Nav.Item>
                                                <Nav.Link eventKey="1" className="px-4 py-2 text-dark me-2" >Weekly</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="2" className="px-4 py-2 text-dark"  >Monthly</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="3" className="px-4 py-2 text-dark"  > Others</Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="1">
                                                <Form>


                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="3">
                                                            <h6 className=''>Select day of Week</h6>
                                                        </Form.Label>
                                                        <Col sm="9">

                                                            <Select menuPlacement="auto" options={options_days}
                                                                name="sp_start_ofDay"
                                                                value={options_days.find(option => option.value && values.sp_start_ofDay && option.value.toString() === values.sp_start_ofDay.toString())}
                                                                id="sp_start_ofDay"
                                                                disabled={true}
                                                                // onChange={(selectedOption) => {
                                                                //     const event = {
                                                                //         target: {
                                                                //             name: "sp_start_ofDay",
                                                                //             value: selectedOption["value"],
                                                                //         },
                                                                //     };
                                                                //     handleChange(event);
                                                                // }}
                                                                // onBlur={(event) => {
                                                                //     handleBlur(event);
                                                                //     setTouched({ ...touched, sp_start_ofDay: true });
                                                                // }}
                                                                className={errors.sp_start_ofDay && touched.sp_start_ofDay ? "input-error" : ""}
                                                            />
                                                            {errors.sp_start_ofDay && touched.sp_start_ofDay && <span className="error">{errors.sp_start_ofDay}</span>}

                                                            <Col xs={12} md={4}>
                                                                {/* <Button variant="primary" className="mt-2 px-4 py2 btn1">Add Payment</Button> */}
                                                            </Col>
                                                        </Col>
                                                    </Form.Group>

                                                </Form>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="2">
                                                <Form>


                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="3">
                                                            <h6 className=''>Select day of Month</h6>
                                                        </Form.Label>
                                                        <Col sm="9">

                                                            <Select menuPlacement="auto" options={options_months}
                                                                name="sp_start_ofDay_mon"
                                                                value={options_months.find(option => option.value && values.sp_start_ofDay_mon && option.value.toString() === values.sp_start_ofDay_mon.toString())}
                                                                id="sp_start_ofDay_mon"
                                                                disabled={true}
                                                                // onChange={(selectedOption) => {
                                                                //     const event = {
                                                                //         target: {
                                                                //             name: "sp_start_ofDay_mon",
                                                                //             value: selectedOption["value"],
                                                                //         },
                                                                //     };
                                                                //     handleChange(event);
                                                                // }}
                                                                // onBlur={(event) => {
                                                                //     handleBlur(event);
                                                                //     setTouched({ ...touched, sp_start_ofDay_mon: true });
                                                                // }}
                                                                className={errors.sp_start_ofDay_mon && touched.sp_start_ofDay_mon ? "input-error" : ""}
                                                            />
                                                            {errors.sp_start_ofDay_mon && touched.sp_start_ofDay_mon && <span className="error">{errors.sp_start_ofDay_mon}</span>}

                                                            <Col xs={12} md={8}>
                                                                {/* <Button variant="primary" className="mt-2 px-4 py2 btn1">Add Payment</Button> */}
                                                            </Col>
                                                        </Col>
                                                    </Form.Group>

                                                </Form>
                                            </Tab.Pane>
                                            {/* <Tab.Pane eventKey="3">
                                                <Form>


                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="12">
                                                            <h6 > <span className='required-field'></span>The payment frequency is based on the subscription's creation date.</h6>
                                                        </Form.Label>
                                                        
                                                    </Form.Group>

                                                </Form>
                                            </Tab.Pane> */}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>

                        </div>
                    </Stack>

                     {/* Payment Mode Section */}
                    <Stack className="mt-4">
                        <div className="my-card p-4 bg-white">
                        <div className="section-heading border-bottom pb-3 mb-3">
                            <h6 className="mb-0">
                            Payment Mode{" "}
                            {["top"].map((placement) => (
                                <OverlayTrigger
                                key={placement}
                                placement={placement}
                                overlay={
                                    <Tooltip
                                    id={`tooltip-${placement}`}
                                    className="custom-tooltip"
                                    >
                                    Note: Please select the payment Modes
                                    </Tooltip>
                                }
                                >
                                <Button variant="secondary" className="no-bdr">
                                    <i className="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                                </Button>
                                </OverlayTrigger>
                            ))}
                            </h6>
                        </div>

                        
                            {/* Payment Gateway Section */}
                            <h6 className="mt-3" id="paymentModes">Payment Gateway</h6>

                            {OnlinePaymentModes.map((OnlineMode, index) => (
                                <div key={index} className="d-flex align-items-center mb-2">
                                    <Form.Check
                                        type="checkbox"
                                        label={OnlineMode.pm_name}
                                        checked={OnlineMode.checked}
                                        onChange={(e) => {
                                            const updatedModes = [...OnlinePaymentModes];
                                            updatedModes[index].checked = e.target.checked;
                                            setOnlinePaymentModes(updatedModes);
                                            handleCheckboxChange(OnlineMode.pm_name, 1, e.target.checked);
                                        }}
                                        className="me-3"
                                    />
                                </div>
                            ))}


                            {/* Non-Payment Gateway Section */}
                            <h6 className="mt-3">Non-Payment Gateway</h6>
                            {nonPaymentModes.map((Mode, index) => (
                                <div key={index} className="d-flex align-items-center mb-2">
                                    <Form.Check
                                        type="checkbox"
                                        label={Mode.pm_name}
                                        checked={Mode.checked}
                                        onChange={(e) => {
                                            const updatedModes = [...nonPaymentModes];
                                            updatedModes[index].checked = e.target.checked;
                                            setNonPaymentModes(updatedModes);
                                            handleCheckboxChange(Mode.pm_name, 2, e.target.checked);
                                        }}
                                        className="me-3"
                                    />
                                </div>
                            ))}

                            {/* Others Section */}
                           
                           <>
                                <Row className="mt-2 align-items-center mb-3">
                                    <Col xs={4}>
                                        <h6 className="mb-0">Others Modes</h6>
                                    </Col>
                                    <Col xs={8} className="d-flex justify-content-end">
                                        {isAdding ? (
                                            <div className="d-flex align-items-center">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="New payment Mode"
                                                    value={newMode || ""}
                                                    onChange={(e) => setNewMode(e.target.value)}
                                                    className="me-2 mb-0"
                                                />
                                                <Button onClick={handleAddMode} variant="primary">
                                                    SAVE
                                                </Button>
                                                <Button
                                                    onClick={() => setIsAdding(false)}
                                                    variant="secondary"
                                                    className="ms-2"
                                                >
                                                    CANCEL
                                                </Button>
                                            </div>
                                        ) : (
                                            <Button onClick={() => setIsAdding(true)} variant="primary">
                                                ADD
                                            </Button>
                                        )}
                                    </Col>
                                </Row>

                                <Table bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {otherModes.length > 0 ?
                                        otherModes.map((Mode, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {isEditing === index ? (
                                                        <Form.Control
                                                            type="text"
                                                            value={editMode || ""}
                                                            onChange={(e) => setEditMode(e.target.value)}
                                                        />
                                                    ) : (
                                                        Mode.copm_name
                                                    )}
                                                </td>
                                                <td>
                                                    {isEditing === index ? (
                                                        <div className="d-flex">
                                                            <Button
                                                                onClick={() => handleSaveEdit(index)}
                                                                variant="success"
                                                                className="me-2"
                                                            >
                                                                Save
                                                            </Button>
                                                            <Button
                                                                onClick={() => setIsEditing(null)}
                                                                variant="secondary"
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex">
                                                            <Button
                                                                onClick={() => handleEditMode(index)}
                                                                variant="primary"
                                                                className="me-2"
                                                            >
                                                                Edit
                                                            </Button>
                                                            <Button
                                                                onClick={() => handleDeleteMode(Mode.pk_copm_key)}
                                                                variant="danger"
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                        :   <tr>
                                                <td colspan="2" style={{ textAlign: 'center' }}>No Data</td>
                                            </tr>}
                                    </tbody>
                                </Table>
                            </>
                           
                                    
                            {errors.paymentModes && touched.paymentModes && (
                                    <p className="text-danger mt-2">{errors.paymentModes.paymentGateway || errors.paymentModes.nonPaymentGateway}</p>
                                )}
                        </div>
                    </Stack>

                <Stack gap={2} className="mt-3">
                    <div className="text-end">
                        <Button variant="contained" className="primary-gray text-white me-2" onClick={handleSave}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" className="primary-filled" disabled={isSubmitting}>
                        Update
                        </Button>
                    </div>
                </Stack>
              

            </div>
        </Form>
    </>
  );
}

export default EditAdmin;