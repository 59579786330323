/*
Purpose: Add student
Created BY: KTH
Date:22/08/2023 
Project: TCMS
*/


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Select from 'react-select';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';


import axios from "axios";
import {
    security_key, options_time_zone,
    options_countries, options_gender, options_days, options_preferedmethodofcontact,
    options_refferalsource, options_campaign, options_status, options_dig_type, options_duration, options_months, BASE_URL
} from "../global";
import 'react-phone-input-2/lib/style.css'; // Import CSS styles for the phone input
import PhoneInput from 'react-phone-input-2';
import format from 'date-fns/format';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';


function AddStudent() {
  
    const [options_parent, setOptions_par] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);
    const [modeData, setMode] = useState("createStudent_direct");
    const [options_yearlevel, setYearLevelOptions] = useState([]);
    const navigate = useNavigate();

   
    const onGroupContainerClick = useCallback(() => {
        navigate("/list-students");
    }, [navigate]);


    const basicSchema = yup.object().shape({
        st_first_name: yup.string().required("First name is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        st_surname: yup.string().required("Last Name is required.").min(1, "Minimum 1 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        st_dob: yup
        .string()
        .nullable(true)
        .test('is-valid-date', 'Please provide a valid date.', function (value) {
          // Check if the value is null or a valid date 
         
          return value === undefined || value === null || value === '' || dayjs(value, 'YYYY-MM-DD').isValid();
        }),
        // st_gender: yup.mixed().required("Gender is required."),
        // st_dob: yup.date().required("Date of birth is required."),
        // st_year_level: yup.mixed().required("Year level is required."),
        fk_sc_id: yup.string().min(2, "Minimum 2 characters required").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        st_email: yup.string().email("Please enter a valid email address").notRequired().matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
            return !value || value && value.includes('.');
          }),
        // st_address: yup.mixed().required("Address is required."),
        // st_city: yup.mixed().required(" City/Town is required."),
        // st_state: yup.mixed().required("State is required."),
        // st_zip: yup.mixed().required("Postcode is required."),
        // st_country: yup.mixed().required("Country is required."),
        st_countryCode: yup.string().notRequired(),
        st_phone: yup
          .string()
          .test(
            'minDigits',
            'Minimum 5 digits required',
            function (value) {
              const { st_countryCode } = this.parent;
              const countryCodeLength = st_countryCode ? st_countryCode.length : 0;
              const phoneLength = value ? value.length : 0;
              const difference = phoneLength - countryCodeLength;
    
              // Check if the difference is less than 5
              return difference >= 5 || difference === 0;
            }
          )
          .notRequired(),

 

        //st_phone: yup.string().min(7, "Minimum 5 digit required").notRequired(),
        // st_timezone: yup.mixed().required("Timezone is required."),
        //st_avatar: yup.mixed().required("Photo is required."),

        sa_login_id: yup.string().required("Username is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
        sa_password: yup.string().min(5, "Minimum 5 characters is required.").required("Password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        sa_conform_password: yup
            .string()
            .oneOf([yup.ref("sa_password"), null], "Password is not matching")
            .required("Confirm password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        // shs_question: yup.mixed().required("Question is required."),
        // shs_answer: yup.mixed().required("Answer is required."),

        st_is_adultlearner: yup.bool(),
        //st_is_adultlearner: yup.boolean(),
        /*tbl_parent*/
        par_firstname: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.string().required("First name is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        }),
        par_surname: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.string().required("Lastname is required.").min(1, "Minimum 1 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        }),

        // par_emailid: yup.string().when('st_is_adultlearner', {
        //     is: false,
        //     then: () => yup.string().email("Please enter a valid email address").required("Email is required."),
        // }),
        par_countryCode: yup.string().notRequired(),
        par_phone: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () =>  yup
            .string()
            .test(
              'minDigits',
              'Minimum 5 digits required',
              function (value) {
                const { par_countryCode } = this.parent;
                const countryCodeLength = par_countryCode ? par_countryCode.length : 0;
                const phoneLength = value ? value.length : 0;
                const difference = phoneLength - countryCodeLength;
      
                // Check if the difference is less than 5
                return difference >= 5 || difference === 0;
              }
            )
            .notRequired(),
        }),



        par_address: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        par_city: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        par_state: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
      //  par_country: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        par_zip: yup.string().min(4, "Minimum 4 characters is required.").max(8, "Maximum 8 characters is required.").notRequired().matches(/^[a-zA-Z0-9\/-]+$/, "Allow only letters, numbers, - and /."),

        /*tbl_parent_auth*/

        // pa_login_id: yup.string().when('st_is_adultlearner', {
        //     is: false,
        //     then: () => yup.string().required("Username is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        //   }),
        //   pa_password: yup.string().when('st_is_adultlearner', {
        //     is: false,
        //     then: () =>  yup.string().min(5, "Minimum 5 characters is required.").required("Password is required."),
        //   }),
        //   pa_conform_password: yup.string().when('st_is_adultlearner', {
        //     is: false,
        //     then: () => yup
        //     .string()
        //     .oneOf([yup.ref("pa_password"), null], "Password is not matching")
        //     .required("Confirm password is required."),
        //   }),





        pa_login_id: yup.string().when(['st_is_adultlearner', 'ex_par_key'], {
            is: (st_is_adultlearner, ex_par_key) =>
                !st_is_adultlearner && ex_par_key == '0',
            then: () => yup.string().required("Username is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
        }),
        pa_password: yup.string().when(['st_is_adultlearner', 'ex_par_key'], {
            is: (st_is_adultlearner, ex_par_key) =>
                !st_is_adultlearner && ex_par_key == '0',
            then: () => yup.string().min(5, "Minimum 5 characters is required.").required("Password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        }),
        pa_conform_password: yup.string().when(['st_is_adultlearner', 'ex_par_key'], {
            is: (st_is_adultlearner, ex_par_key) =>
                !st_is_adultlearner && ex_par_key == '0',
            then: () => yup
                .string()
                .oneOf([yup.ref("pa_password"), null], "Password is not matching")
                .required("Confirm password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        }),


        // pa_shp_question: yup.mixed().required("Question is required."),
        //  pa_shp_answer: yup.mixed().required("Answer is required."),


        sp_start_ofDay: yup
        .string()
        .nullable()
        .test('is-required', 'At least one select dropdown is required', function (value) {
          const { sp_start_ofDay, sp_start_ofDay_mon, /* add more fields if needed */ } = this.parent;
    
          return !!sp_start_ofDay || !!sp_start_ofDay_mon; // Add more conditions if needed
        }),
    
      sp_start_ofDay_mon: yup
        .string()
        .nullable()
        .test('is-required', 'At least one select dropdown is required', function (value) {
          const { sp_start_ofDay, sp_start_ofDay_mon, /* add more fields if needed */ } = this.parent;
    
          return !!sp_start_ofDay || !!sp_start_ofDay_mon; // Add more conditions if needed
        }),
        lead_pref_tim_contact: yup
            .string()
            .nullable(true)
            .test('is-valid-time', 'Please provide a valid time.', function (value) {
            // Check if the value is null or a valid time
            return value === undefined || value === null || value === '' || dayjs(value, 'HH:mm:ss').isValid();
            }),
        ls_date: yup
        .string()
        .nullable(true)
        .test('is-valid-date', 'Please provide a valid date.', function (value) {
          // Check if the value is null or a valid date 
         
          return value === undefined || value === null || value === '' || dayjs(value, 'YYYY-MM-DD').isValid();
        }),

        /*tbl_lead*/
        // lead_pref_meth_contact: yup.mixed().required("Method of contact is required."), //My point reset date-10
        // lead_pref_tim_contact: yup.mixed().required("Time to contact is required."),
        // lead_ref_source: yup.mixed().required("Referal source is required."),
        // lead_ref_campaign: yup.mixed().required("Referal campaign is required."),
        // ls_date: yup.date().required("Date is required."),

         //ls_status: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    ls_description: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        /*tbl_dynostics*/
        // la_date: yup.date().required("Date is required."),
        //la_duration: yup.mixed().required("Duration is required."),
        // la_assessor: yup.string().required("Assessor is required."),
         la_goals:  yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
         la_notes:  yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
         la_outcome:  yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        // la_type:  yup.mixed().required("Type is required."),
        ps_relationship: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.string().required("Relationship is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        }),


        paymentMode: yup.mixed().required("Payment Mode is required."),

    });

    

    const [Id, setId] = useState(null);
    //Get key value start
    const location = useLocation();

    useEffect(() => {

        if (location.state && location.state.pk_student_key) {

            const { pk_student_key } = location.state || {};

            const extractedStafId = pk_student_key;


            if (extractedStafId !== '' && extractedStafId !== undefined) {
                setId(extractedStafId);
                fetchData(extractedStafId);
            }else{
                navigate("/list-students");
            }
        }
    }, [location]);
    //Get key value end

    const [options_assessor_name, setAssesOptions] = useState([]);
    const [StudentData, setStudentData] = useState({});
    const [imageUrl, setImageUrl] = useState('');

    //Api call start

    const fetchData = async (id) => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getSingleLead_new",
                pk_lead_id: id
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setStudentData(response.data.data[0]);
                    setMode("StudentConvert");

                    if (response.data.data[0]['st_avatar'] != null && response.data.data[0]['st_avatar'] != '') {



                        const fullImageUrl = `${response.data.data[0]['st_avatar']}`;

                        setImageUrl(fullImageUrl);
                    }
                   
                }

                
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    //Api call End
    const [options_paymentModes, setOptionsPaymentModes] = useState([]);
    const [PaymentModesData, setPaymentModesData] = useState([]);
    
    //API Payment Mode start
    const fetchPaymentData = async () => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "centerWisePaymentMode",
                fk_cent_key: sessionStorage.getItem('key')
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                     // Assuming the API response is an array of objects with 'id' and 'value' properties
                    const formattedOptionsPayment = response.data.data.map(option => ({
                        value: option.pk_cpm_key,
                        label: option.pm_name
                    }));
                    formattedOptionsPayment.unshift({
                        value: '',
                        label: 'Select'
                    });
                    setOptionsPaymentModes(formattedOptionsPayment);   
                    setPaymentModesData(response.data.data);                    

                }else{
                    setOptionsPaymentModes([{
                        value: '',
                        label: 'Select'
                    }]);   
                    setPaymentModesData([]);  
                }
            } else {
                    setOptionsPaymentModes([{
                        value: '',
                        label: 'Select'
                    }]);   
                    setPaymentModesData([]); 
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    // API Payment mode end
    useEffect(() => {
        fetchPaymentData();
    }, []);


    useEffect(() => {
        // Make an API request to fetch the options
        axios.post(BASE_URL + 'API/api_index.php', {
            security_key: security_key,
            fk_cent_key: sessionStorage.getItem('key'),
            mode: "getAllParentbyCenter"
        })
            .then(response_par => {
                // Assuming the API response is an array of objects with 'id' and 'value' properties
                const formattedOptions_par = response_par.data.data.map(option => ({
                    value: option.pk_par_key,
                    label: option.par_firstname + ' ' + option.par_surname
                }));
                formattedOptions_par.unshift({
                    value: '0',
                    label: 'Select Existing Parent'
                });
                setOptions_par(formattedOptions_par);
            })
            .catch(error => {
                console.error('Error fetching options from API: ', error);
            });

        axios.post(BASE_URL + 'API/api_index.php', {
            security_key: security_key,
            country_id: sessionStorage.getItem('country_code'),
            mode: "getCountryYearLevel"
        })
            .then(response_year => {
                // Assuming the API response is an array of objects with 'id' and 'value' properties
                const formattedyearOptions = response_year.data.data.map(option => ({
                    value: option.activity_level_id,
                    label: option.level_name
                }));
                setYearLevelOptions(formattedyearOptions);
            })
            .catch(error => {
                console.error('Error fetching options from API: ', error);
            });


        axios.post(BASE_URL + 'API/api_index.php', {
            security_key: security_key,
            center_key: sessionStorage.getItem('key'),
            mode: "getAllassessorData"
        })
            .then(response_asses => {
                // Assuming the API response is an array of objects with 'id' and 'value' properties
                const formattedAssesOptions = response_asses.data.data.map(option => ({
                    value: option.first_name + ' ' + option.last_name,
                    label: option.first_name + ' ' + option.last_name
                }));
                setAssesOptions(formattedAssesOptions);
            })
            .catch(error => {
                console.error('Error fetching options from API: ', error);
            });
    }, []);



    const initialValues = {
        /*tbl_student*/
        pk_student_key: StudentData ? StudentData.pk_student_key : '',
        pk_lead_key: StudentData ? StudentData.pk_lead_key : '',
        pk_par_key: StudentData ? StudentData.pk_par_key : '',

        ex_par_key: StudentData ? StudentData.ex_par_key != null && StudentData.ex_par_key != '' ? StudentData.ex_par_key : '0' : '0',

        /*tbl_student*/
        st_first_name: StudentData ? StudentData.st_first_name : '',
        st_surname: StudentData ? StudentData.st_surname : '',
        st_gender: StudentData ? StudentData.st_gender : '',
        st_dob: StudentData ? StudentData.st_dob : '',
        st_year_level: StudentData ? StudentData.st_year_level : '',
        fk_sc_id: StudentData ? StudentData.fk_sc_id : '',
        st_email: StudentData ? StudentData.st_email : '',
        // st_address: StudentData ? StudentData.st_address : '', 
        // st_city: StudentData ? StudentData.st_city : '',
        // st_state: StudentData ? StudentData.st_state : '',
        // st_country: StudentData ? StudentData.st_country : '', 
        // st_zip: StudentData ? StudentData.st_zip : '',
        st_phone: StudentData ? StudentData.st_phone : '', st_timezone: StudentData ? StudentData.st_timezone != null && StudentData.st_timezone != '' ? StudentData.st_timezone : sessionStorage.getItem('time_zone') : sessionStorage.getItem('time_zone'),

        st_is_adultlearner: StudentData ? StudentData.st_is_adultlearner == 1 ? true : false : false,
        fk_cent_key: StudentData ? StudentData.fk_cent_key : '',
        st_avatar: StudentData ? StudentData.st_avatar : '',
        st_countryCode:'',
        par_countryCode:'',
        /*tbl_student_auth*/
        sa_login_id: StudentData ? StudentData.as_date != null && StudentData.as_date != '' ? StudentData.pk_student_key+ '@123' : '' : '',
        sa_password: StudentData ? StudentData.as_date != null && StudentData.as_date != '' ? 'Password@123' : '' : '',
        sa_conform_password: StudentData ? StudentData.as_date != null && StudentData.as_date != '' ? 'Password@123' : '' : '',
        shs_question: StudentData ? StudentData.as_date != null && StudentData.as_date != '' ? 'question' : '' : '',
        shs_answer: StudentData ? StudentData.as_date != null && StudentData.as_date != '' ? 'answer' : '' : '',

        /*tbl_parent*/
        par_firstname: StudentData ? StudentData.par_firstname : '',
        par_surname: StudentData ? StudentData.par_surname : '',
        par_emailid: StudentData ? StudentData.par_emailid : '',
        par_phone: StudentData ? StudentData.par_phone : '',

        par_address: StudentData ? StudentData.par_address : '',
        par_city: StudentData ? StudentData.par_city : '',
        par_state: StudentData ? StudentData.par_state : '',
        //par_country: StudentData ? StudentData.par_country : '', 
        par_country: StudentData ? StudentData.par_country != null && StudentData.par_country != '' ? StudentData.par_country : sessionStorage.getItem('country_code') != null && sessionStorage.getItem('country_code') != '' ? sessionStorage.getItem('country_code') : 'SG' : sessionStorage.getItem('country_code') != null && sessionStorage.getItem('country_code') != '' ? sessionStorage.getItem('country_code') : 'SG',
        par_zip: StudentData ? StudentData.par_zip : '',

        /*tbl_parent_auth*/
        pa_login_id: "",
        pa_password: "",
        pa_conform_password: "",
        pa_shp_question: "",
        pa_shp_answer: "",



        /*tbl_lead*/
        lead_pref_meth_contact: StudentData ? StudentData.lead_pref_meth_contact : '', //My point reset date-10
        lead_pref_tim_contact: StudentData ? StudentData.lead_pref_tim_contact : '',
        lead_ref_source: StudentData ? StudentData.lead_ref_source : '',
        lead_ref_campaign: StudentData ? StudentData.lead_ref_campaign : '',
        ls_date: StudentData ? StudentData.ls_date : '',
        ls_status: StudentData ? StudentData.ls_status : '',
        ls_description: StudentData ? StudentData.ls_description : '',

        /*tbl_dynostics*/
        la_date: null,
        la_duration: StudentData ? StudentData.la_duration : '',
        la_assessor: StudentData ? StudentData.la_assessor : '',
        la_goals: StudentData ? StudentData.la_goals : '',
        la_notes: StudentData ? StudentData.la_notes : '',
        la_outcome: StudentData ? StudentData.la_outcome : '',
        la_type: StudentData ? StudentData.la_type : '', // Online or centre
        ps_relationship: StudentData ? StudentData.ps_relationship : '',
        fk_cent_key: sessionStorage.getItem('key'),
        created_by: sessionStorage.getItem('key'),

        sp_start_ofDay: "",
        sp_start_ofDay_mon: "",

        paymentMode:'',
        fk_pm_key:'',

    };

    const [clickedValue, setClickedValue] = useState(1);
    // Event handler for the first element
    const handleOnClick1 = () => {
        // Set the clicked value to 1
        setClickedValue(1);
    };

    // Event handler for the second element
    const handleOnClick2 = () => {
        // Set the clicked value to 2
        setClickedValue(2);
    };
  
    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        // onSubmit,
        onSubmit: async (formValues) => {

            setIsSubmitting(true);


            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: modeData,
                    payment_type: clickedValue,
                    ...formValues,
                });

                if (response.data.success === true) {
                   
                    if (response.data.data === 1) {
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/list-students");
                            setIsSubmitting(false); // Re-enable the submit button
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds


                    } else if (response.data.data === 2) {

                        toast.error("Student username is not available", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        handleScrollAndFocus('sa_login_id');

                        setIsSubmitting(false); // Re-enable the submit button

                    } else if (response.data.data === 3) {
                        toast.error("Parents / Guardians username is not available", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                        handleScrollAndFocus('pa_login_id');
                        setIsSubmitting(false); // Re-enable the submit button
                    }else if (response.data.data == 4) {
                        toast.error("Year level is not available, Try another option", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                        handleScrollAndFocus('st_year_level');
                        setIsSubmitting(false); // Re-enable the submit button
                    } else {
                        toast.error("Failure! Unable to create student. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to create student. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to create student. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            } finally {
                setIsSubmitting(false); // Re-enable the submit button

            }

        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    //const handleDateChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;
    const fileInputRef = useRef(null);

    const saLoginIdRef = useRef(null);
    const paLoginIdRef = useRef(null);
    const stYearLevelRef = useRef(null);

    const scrollToField = (fieldRef) => {
        if (fieldRef && fieldRef.current) {
            fieldRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            fieldRef.current.focus();
        }
    };

    const handleScrollAndFocus = (fieldId) => {
        switch (fieldId) {
            case 'sa_login_id':
                scrollToField(saLoginIdRef);
                break;
            case 'pa_login_id':
                scrollToField(paLoginIdRef);
                break;
            case 'st_year_level':
                scrollToField(stYearLevelRef);
                break;
            default:
                break;
        }
    };
   
    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields

    };




    const handleUsernameBlur = useCallback(async () => {
        const username = formik.values.sa_login_id;
        // if (username) {
        //     try {
        //         const response = await axios.post(BASE_URL + "API/api_index.php", {
        //             userID: username,
        //             security_key: security_key,
        //             mode: "checkStudentUserId",
        //         });

        //         if (response.data.success === true) {
        //             formik.setFieldTouched("sa_login_id", true, false);
        //             formik.setFieldError("sa_login_id", "Username is not available");
        //         } else {
        //             formik.setFieldTouched("sa_login_id", true, false);
        //             formik.setFieldError("sa_login_id", null);
        //         }
        //     } catch (error) {
        //         console.error("Error checking username:", error);
        //         formik.setFieldError("sa_login_id", null);
        //     }
        // }
    }, [formik.values.sa_login_id]);


    const handleUsernameBlur1 = useCallback(async () => {
        const username1 = formik.values.pa_login_id;
        // if (username1) {
        //     try {
        //         const response = await axios.post(BASE_URL + "API/api_index.php", {
        //             userID: username1,
        //             security_key: security_key,
        //             mode: "checkParentUserId",
        //         });

        //         if (response.data.success === true) {
        //             formik.setFieldTouched("pa_login_id", true, false);
        //             formik.setFieldError("pa_login_id", "Username is not available");
        //         } else {
        //             formik.setFieldTouched("pa_login_id", true, false);
        //             formik.setFieldError("pa_login_id", null);
        //         }
        //     } catch (error) {
        //         console.error("Error checking username:", error);
        //         formik.setFieldError("pa_login_id", null);
        //     }
        // }
    }, [formik.values.pa_login_id]);

    function handelRemove(event) {
        event.preventDefault();
        setImageUrl('');
    }

    // const firstErrorInputRef = useRef(null);

    // useEffect(() => {
    //   
    //   if (errors && Object.keys(errors).length > 0) {
       
    //     const firstErrorInput = document.querySelector(
    //       `[id="${Object.keys(errors)[0]}"]`
    //     );
    
    //     if (firstErrorInput) {
    //       firstErrorInputRef.current = firstErrorInput;
    //       firstErrorInputRef.current.scrollIntoView({
    //         behavior: 'smooth',
    //         block: 'start',
    //       });

    //       firstErrorInputRef.current = null;
    //     }
    //   }
    // }, [errors]);

    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) { const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;
          
            const errorFieldElement = document.getElementById(firstErrorField);
          
            if (errorFieldElement) {
               errorFieldElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
            } else {
             // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
         //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
      }, [formik]);
      
      console.log(formik.values);
    return (

        <>
            {/* React alert */}
             <ToastContainer position="top-center" />
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                <div className="">

                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'> New Student </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">Student</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                    </div>

                    {/* --------------Student Particulars--------------- */}

                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Student Particulars </h6>
                            </div>
                            <Row>

                                {/* --------------Student First Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">First Name</h6>
                                    <Form.Control
                                        type="text"

                                        value={values.st_first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_first_name"
                                        className={errors.st_first_name && touched.st_first_name ? "input-error" : ""}
                                    />
                                    {errors.st_first_name && touched.st_first_name && <p className="error">{errors.st_first_name}</p>}
                                </Col>

                                {/* --------------Student SurName--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Last name</h6>
                                    <Form.Control
                                        type="text"

                                        value={values.st_surname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_surname"
                                        className={errors.st_surname && touched.st_surname ? "input-error" : ""}
                                    />
                                    {errors.st_surname && touched.st_surname && <p className="error">{errors.st_surname}</p>}
                                </Col>


                                {/* --------------Student Gender--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Gender</h6>
                                    <Select menuPlacement="auto" options={options_gender}
                                        name="st_gender"
                                        value={options_gender.find(option => option.value && values.st_gender && option.value.toString() === values.st_gender.toString())}
                                        id="st_gender"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "st_gender",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, st_gender: true });
                                        }}
                                        className={errors.st_gender && touched.st_gender ? "input-error" : ""}
                                    />
                                    {errors.st_gender && touched.st_gender && <p className="error">{errors.st_gender}</p>}
                                </Col>

                                {/* --------------Student Date of Birth--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Date of Birth</h6>
{/*                                     
                                    <Form.Control
                                        type="date"

                                        max={new Date().toISOString().split("T")[0]}
                                        value={values.st_dob}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_dob"
                                        dateFormat="yyyy-MM-dd"
                                        className={errors.st_dob && touched.st_dob ? "input-error" : ""}
                                    /> */}
                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.st_dob, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                
                                                const event = {
                                                target: {
                                                    name: "st_dob",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, st_dob: true });
                                            }}
                                            name="st_dob"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'st_dob',
                                                    required: false,
                                                },
                                                }}
                                            className={errors.st_dob && touched.st_dob ? "input-error w-100" : "w-100"}
                                            maxDate={dayjs()}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                                    {errors.st_dob && touched.st_dob && <span className="error">{errors.st_dob}</span>}
                                </Col>


                                {/* --------------Student Year Level--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Year Level</h6>
                                    <Select menuPlacement="auto" options={options_yearlevel}
                                        name="st_year_level"
                                        value={options_yearlevel.find(option => option.value && values.st_year_level && option.value.toString() === values.st_year_level.toString())}
                                        id="st_year_level"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "st_year_level",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, st_year_level: true });
                                        }}
                                        className={errors.st_year_level && touched.st_year_level ? "input-error" : ""}
                                        ref={stYearLevelRef}
                                    />
                                    {errors.st_year_level && touched.st_year_level && <p className="error">{errors.st_year_level}</p>}
                                </Col>

                                {/* --------------Student School--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">School</h6>
                                    <Form.Control
                                        type="text"

                                        value={values.fk_sc_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="fk_sc_id"
                                        className={errors.fk_sc_id && touched.fk_sc_id ? "input-error" : ""}
                                    />
                                    {errors.fk_sc_id && touched.fk_sc_id && <p className="error">{errors.fk_sc_id}</p>}
                                </Col>

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">School</h6>
                                     <Select menuPlacement="auto"  options={options_school}
                                     name="fk_sc_id"
                                     value={options_school.find(option => option.value && values.fk_sc_id && option.value.toString() === values.fk_sc_id.toString())}
                                     id="fk_sc_id"
                                     onChange={(selectedOption) => {
                                         const event = {
                                             target: {
                                                 name: "fk_sc_id",
                                                 value: selectedOption["value"],
                                             },
                                         };
                                         handleChange(event);
                                     }}
                                     onBlur={(event) => {
                                         handleBlur(event);
                                         setTouched({ ...touched, fk_sc_id: true });
                                     }}
                                     className={errors.fk_sc_id && touched.fk_sc_id ? "input-error" : ""} />
                                      {errors.fk_sc_id && touched.fk_sc_id && <p className="error">{errors.fk_sc_id}</p>}
                                </Col> */}

                                {/* --------------Student Email--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Email <a href={`mailto:userID@example.com`} target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i class="fa-solid fa-envelope me-2"></i></a></h6>
                                    <Form.Control
                                        type="text"

                                        value={values.st_email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_email"
                                        className={errors.st_email && touched.st_email ? "input-error" : ""}
                                    />
                                    {errors.st_email && touched.st_email && <p className="error">{errors.st_email}</p>}
                                </Col>



                                {/* --------------Student city--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">City/Town</h6>
                                    <Form.Control type="text" 
                                    value={values.st_city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="st_city"
                                    className={errors.st_city && touched.st_city ? "input-error" : ""}
                                     />
                                    {errors.st_city && touched.st_city && <p className="error">{errors.st_city}</p>}
                                </Col> */}

                                {/* --------------Student state--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">County/Region/State</h6>
                                    <Form.Control type="text" 
                                     value={values.st_state}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="st_state"
                                     className={errors.st_state && touched.st_state ? "input-error" : ""} />
                                      {errors.st_state && touched.st_state && <p className="error">{errors.st_state}</p>}
                                </Col> */}

                                {/* --------------Student Country--------------- */}
                                {/*                      
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Country</h6>
                                     <Select menuPlacement="auto"  options={options_countries}
                                    name="st_country"
                                    value={options_countries.find(option => option.value && values.st_country && option.value.toString() === values.st_country.toString())}
                                    id="st_country"
                                    onChange={(selectedOption) => {
                                        const event = {
                                            target: {
                                                name: "st_country",
                                                value: selectedOption["value"],
                                            },
                                        };
                                        handleChange(event);
                                    }}
                                    onBlur={(event) => {
                                        handleBlur(event);
                                        setTouched({ ...touched, st_country: true });
                                    }}
                                    className={errors.st_country && touched.st_country ? "input-error" : ""}
                                     />
                                     {errors.st_country && touched.st_country && <p className="error">{errors.st_country}</p>}
                                </Col> */}


                                {/* --------------Student Postal Code--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Postal Code</h6>
                                    <Form.Control type="text" 
                                     value={values.st_zip}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="st_zip"
                                     className={errors.st_zip && touched.st_zip ? "input-error" : ""}
                                    />
                                    {errors.st_zip && touched.st_zip && <p className="error">{errors.st_zip}</p>}
                                </Col> */}



                                {/* --------------Student phone--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Contact Number <a href='https://web.whatsapp.com/' target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i className="fa fa-whatsapp me-2"></i></a></h6>
                                    
                                    <PhoneInput
                                        name="st_phone"
                                         countryCodeEditable={false}
                                        onlyCountries={['sg','nz','gb','au']}
                                        country={sessionStorage.getItem('country_alph_code') != null && sessionStorage.getItem('country_alph_code') != '' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                        value={values.st_phone} 
                                        onChange={(phone, country) => 
                                        
                                            setValues({
                                            ...values,
                                            st_phone: phone,
                                            st_countryCode: country.dialCode
                                            })
                                        }
                                        // onChange={(value) => {
                                        //     const event = {
                                        //         target: {
                                        //             name: "st_phone",
                                        //             value: value,
                                        //         },
                                        //     };
                                        //     handleChange(event);
                                        // }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, st_phone: true });
                                        }}
                                        isValid={(value, country) => {
                                            if (value.match(/12345/)) {
                                              return 'Invalid value: '+value+', '+country.name;
                                            } else if (value.match(/1234/)) {
                                              return false;
                                            } else {
                                              return true;
                                            }
                                          }}
                                        inputProps={{
                                            id: 'st_phone', required: true}}
                                        
                                        inputId="st_phone"
                                        inputClass={errors.st_phone && touched.st_phone ? 'input-error form-control' : 'form-control'}
                                    />
                                    {errors.st_phone && touched.st_phone && <span className="error">{errors.st_phone}</span>}
                                    {/* {errors.st_phone && touched.st_phone && <p className="error">{errors.st_phone}</p>} */}
                                </Col>




                                {/* --------------Student phone--------------- */}
                                {!imageUrl && (<Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Photo</h6>
                                    <Form.Control type="file"
                                        name="st_avatar"
                                        id="st_avatar"
                                        accept=".png, .jpeg, .jpg, .jfif"
                                        onChange={(event) => {
                                            setTouched({ ...touched, st_avatar: true });
                                            if (event.target.files.length > 0) {
                                                const fileName = event.target.files[0];
                                                async function handleFileUpload(fileName) {
                                                    try {
                                                        // Create a FormData object to send the form data, including the image file
                                                        const formData = new FormData();
                                                        formData.append("mode", "file_s3_upload_image");
                                                        formData.append("user", "student");
                                                        formData.append("image", fileName); // Append the image file

                                                        // Make an API request here with the FormData object
                                                        const response_file = await axios.post(BASE_URL + 'API/upload/index.php', formData);

                                                        // Handle the API response here
                                                        if (response_file.data.success === true) {
                                                          
                                                            handleChange({ target: { name: "st_avatar", value: response_file.data.data } });
                                                        } else {
                                                           
                                                            // handleChange((prevState) => ({
                                                            //     ...prevState,
                                                            //     st_avatar: null,
                                                            //   }));
                                                            handleChange({ target: { name: "st_avatar", value: null } }); // Clear the input value
                                                            fileInputRef.current.value = null;
                                                        }

                                                        // You can also perform further actions based on the API response
                                                        // For example, show a success message or redirect to another page.
                                                    } catch (error) { 
                                                        handleChange({ target: { name: "st_avatar", value: null } }); // Clear the input value
                                                        fileInputRef.current.value = null;
                                                    }
                                                }

                                                // Call the async function when needed, passing the fileName as an argument
                                                handleFileUpload(fileName);


                                            } else {
                                                // handleChange(event);
                                                handleChange({ target: { name: "st_avatar", value: null } }); // Clear the input value
                                                fileInputRef.current.value = null;
                                            }
                                        }}
                                        ref={fileInputRef}
                                        className={errors.st_avatar && touched.st_avatar ? "input-error" : ""}
                                    />
                                    {errors.st_avatar && touched.st_avatar && <p className="error">{errors.st_avatar}</p>}
                                </Col>
                                )}

                                {imageUrl && (
                                    <Col lg="4" md="6" sm="12" className='d-flex justify-content-between'>
                                        <img src={imageUrl} alt='image' width="50" height="50" />
                                        <div className="text-end">
                                            <Button variant="contained" className="primary-filled  btn btn-contained" onClick={handelRemove}>X</Button>
                                        </div>
                                    </Col>
                                )}



                                {/* --------------Contact Checkbox--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>

                                    <Form.Check
                                        type="checkbox"
                                        label="Adult Learner"
                                        id="st_is_adultlearner"
                                        name="st_is_adultlearner"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        checked={formik.values.st_is_adultlearner}
                                        className='mt-0'

                                    />

                                    {errors.st_is_adultlearner && touched.st_is_adultlearner && <p className="error">{errors.st_is_adultlearner}</p>}
                                </Col>


                                {/* --------------Contact Address1--------------- */}
                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Address</h6>
                                    <Form.Control as="textarea" 
                                     rows={8} 
                                    value={values.st_address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="st_address"
                                    className={errors.st_address && touched.st_address ? "input-error control_textarea" : "control_textarea"}
                                     />
                                      {errors.st_address && touched.st_address && <p className="error">{errors.st_address}</p>}
                                </Col> */}

                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Student Particulars End--------------- */}

                    {/* --------------student Login Details--------------- */}

                    <Stack className='mt-4' style={{ display: StudentData.as_date != null && StudentData.as_date != '' ? 'none' : 'block' }}>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Student App Login</h6>
                            </div>

                            <Row>
                                {/* --------------Student Username--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Username</h6>
                                    <Form.Control type="text"
                                        name="sa_login_id"
                                        autoComplete="off"

                                        value={values.sa_login_id}
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                        onBlur={handleBlur}
                                        id="sa_login_id"
                                       // onBlur={handleUsernameBlur}
                                        className={errors.sa_login_id && touched.sa_login_id ? "input-error" : "mb-0"}
                                        ref={saLoginIdRef}
                                    />
                                    {errors.sa_login_id && touched.sa_login_id && <p className="error">{errors.sa_login_id}</p>}
                                </Col>

                                {/* --------------Student Password--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Password</h6>
                                    <Form.Control type="password"
                                        autoComplete="new-password"

                                        value={values.sa_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sa_password"
                                        className={errors.sa_password && touched.sa_password ? "input-error" : "mb-0"}
                                    />
                                    {errors.sa_password && touched.sa_password && <p className="error">{errors.sa_password}</p>}
                                </Col>


                                {/* --------------Student Confirm Password--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Confirm Password</h6>
                                    <Form.Control type="password"
                                        autoComplete="new-password"

                                        value={values.sa_conform_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sa_conform_password"
                                        className={errors.sa_conform_password && touched.sa_conform_password ? "input-error" : "mb-0"}
                                    />
                                    {errors.sa_conform_password && touched.sa_conform_password && <p className="error">{errors.sa_conform_password}</p>}
                                </Col>

                                {/* --------------Student Secret Question--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>
                                    <h6 className="form-lable">Secret Question</h6>
                                    <Form.Control type="text"
                                        value={values.shs_question}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="shs_question"
                                        className={errors.shs_question && touched.shs_question ? "input-error" : ""}
                                    />
                                    {errors.shs_question && touched.shs_question && <p className="error">{errors.shs_question}</p>}
                                </Col>

                                {/* --------------Student Secrect Answer--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>
                                    <h6 className="form-lable">Secret Answer</h6>
                                    <Form.Control type="text"
                                        value={values.shs_answer}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="shs_answer"
                                        className={errors.shs_answer && touched.shs_answer ? "input-error" : ""}
                                    />
                                    {errors.shs_answer && touched.shs_answer && <p className="error">{errors.shs_answer}</p>}
                                </Col>



                            </Row>
                        </div>
                    </Stack>


                    {/* --------------Contact Details--------------- */}

                    <Stack className='mt-4' style={{ display: formik.values.st_is_adultlearner != null && formik.values.st_is_adultlearner === true ? 'none' : 'block' }}>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3 row'>
                                <Col lg="8" md="8" sm="12" className='position-relative'>
                                    <h6 className='mb-0'>Parents / Guardians Details </h6></Col>

                                <Col lg="4" md="4" sm="12" className='position-relative' style={{ display: modeData != null && modeData == 'StudentConvert' ? 'none' : 'block' }}>
                                    {/* <h6 className="form-lable required-field">Parent Name</h6> */}
                                    <Select menuPlacement="auto" options={options_parent}
                                        name="ex_par_key"
                                        value={options_parent.find(option => option.value && values.ex_par_key && option.value.toString() === values.ex_par_key.toString())}
                                        id="ex_par_key"
                                        //  onChange={(selectedOption) => {
                                        //      const event = {
                                        //          target: {
                                        //              name: "ex_par_key",
                                        //              value: selectedOption["value"],
                                        //          },
                                        //      };
                                        //      handleChange(event);
                                        //  }}
                                        onChange={async (selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "ex_par_key",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);

                                            // Fetch data based on the selected country
                                            try {

                                                const response = await axios.post(BASE_URL + 'API/api_index.php', {
                                                    security_key: security_key,
                                                    mode: "getSingleParentbyCenter",
                                                    pk_par_key: selectedOption["value"]
                                                });
                                              
                                                if (response.data.success === true) {
                                                    if (response.data.data.length > 0) {
                                                        
                                                        handleChange({ target: { name: 'ps_relationship', value: response.data.data[0].ps_relationship } });
                                                        handleChange({ target: { name: 'par_firstname', value: response.data.data[0].par_firstname } });
                                                        handleChange({ target: { name: 'par_surname', value: response.data.data[0].par_surname } });
                                                        handleChange({ target: { name: 'par_emailid', value: response.data.data[0].par_emailid } });
                                                        handleChange({ target: { name: 'par_phone', value: response.data.data[0].par_phone } });
                                                        handleChange({ target: { name: 'par_address', value: response.data.data[0].par_address } });
                                                        handleChange({ target: { name: 'par_city', value: response.data.data[0].par_city } });
                                                        handleChange({ target: { name: 'par_country', value: response.data.data[0].par_country } });
                                                        handleChange({ target: { name: 'par_state', value: response.data.data[0].par_state } });
                                                        handleChange({ target: { name: 'par_zip', value: response.data.data[0].par_zip } });

                                                    } else {
                                                        
                                                        handleChange({ target: { name: 'ps_relationship', value: '' } });
                                                        handleChange({ target: { name: 'par_firstname', value: '' } });
                                                        handleChange({ target: { name: 'par_surname', value: '' } });
                                                        handleChange({ target: { name: 'par_emailid', value: '' } });
                                                        handleChange({ target: { name: 'par_phone', value: '' } });
                                                        handleChange({ target: { name: 'par_address', value: '' } });
                                                        handleChange({ target: { name: 'par_city', value: '' } });
                                                        handleChange({ target: { name: 'par_country', value: '' } });
                                                        handleChange({ target: { name: 'par_state', value: '' } });
                                                        handleChange({ target: { name: 'par_zip', value: '' } });
                                                    }

                                                } else {
                                                  //  console.log("Error fetching abbreviation data");
                                                }

                                                // Update the "yl_abbreviation" field in the Formik values

                                            } catch (error) {
                                                // Handle any errors that occur during the API call
                                                console.error("Error fetching abbreviation data:", error);
                                            }
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, ex_par_key: true });
                                        }}
                                        className={errors.ex_par_key && touched.ex_par_key ? "input-error" : "mb-0"}
                                    />
                                    {errors.ex_par_key && touched.ex_par_key && <p className="error">{errors.ex_par_key}</p>}
                                </Col>

                            </div>

                            <Row>



                                {/* --------------Student Parents / Guardians --------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Relationship</h6>
                                    <Form.Control type="text"
                                        value={values.ps_relationship}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ps_relationship"
                                        className={errors.ps_relationship && touched.ps_relationship ? "input-error" : ""}
                                    />
                                    {errors.ps_relationship && touched.ps_relationship && <p className="error">{errors.ps_relationship}</p>}
                                </Col>


                                {/* --------------Contact First Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">First Name</h6>
                                    <Form.Control type="text"
                                        value={values.par_firstname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_firstname"
                                        className={errors.par_firstname && touched.par_firstname ? "input-error" : ""} />
                                    {errors.par_firstname && touched.par_firstname && <p className="error">{errors.par_firstname}</p>}
                                </Col>

                                {/* --------------Contact SurName--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Last Name</h6>
                                    <Form.Control type="text"
                                        value={values.par_surname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_surname"
                                        className={errors.par_surname && touched.par_surname ? "input-error" : ""} />
                                    {errors.par_surname && touched.par_surname && <p className="error">{errors.par_surname}</p>}
                                </Col>

                                {/* --------------Contact Email--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Email <a href={`mailto:userID@example.com`} target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i class="fa-solid fa-envelope me-2"></i></a></h6>
                                    <Form.Control type="email"
                                        value={values.par_emailid}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_emailid"
                                        className={errors.par_emailid && touched.par_emailid ? "input-error" : "mb-0"}
                                    />
                                    {errors.par_emailid && touched.par_emailid && <p className="error">{errors.par_emailid}</p>}
                                </Col>

                                {/* --------------Student phone--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Contact Number <a href='https://web.whatsapp.com/' target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i className="fa fa-whatsapp me-2"></i></a></h6>
                                    {/* <Form.Control type="number" 
                                     value={values.par_phone}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="par_phone"
                                     className={errors.par_phone && touched.par_phone ? "input-error" : ""} 
                                      /> */}
                                    <PhoneInput
                                        name="par_phone"
                                         countryCodeEditable={false}
                                        onlyCountries={['sg','nz','gb','au']}
                                        country={sessionStorage.getItem('country_alph_code') != null && sessionStorage.getItem('country_alph_code') != '' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                        value={values.par_phone}
                                        onChange={(phone, country) => 
                                        
                                            setValues({
                                            ...values,
                                            par_phone: phone,
                                            par_countryCode: country.dialCode
                                            })
                                        }
                                        // onChange={(value) => {
                                        //     const event = {
                                        //         target: {
                                        //             name: "par_phone",
                                        //             value: value,
                                        //         },
                                        //     };
                                        //     handleChange(event);
                                        // }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, par_phone: true });
                                        }}
                                        inputProps={{id: 'par_phone'}}
                                        id="par_phone"
                                        inputClass={errors.par_phone && touched.par_phone ? 'input-error form-control' : 'form-control'}
                                    />
                                    {errors.par_phone && touched.par_phone && <span className="error">{errors.par_phone}</span>}
                                </Col>





                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Contact Details End--------------- */}


                    {/* --------------Contact Details--------------- */}

                    <Stack className='mt-4' style={{ display: (formik.values.st_is_adultlearner != null && formik.values.st_is_adultlearner === true) || formik.values.ex_par_key != '0' ? 'none' : 'block' }} >
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Parent App Login</h6>
                            </div>

                            <Row>
                                {/* --------------Student Username--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Username</h6>
                                    <Form.Control type="text"
                                        value={values.pa_login_id}
                                        autoComplete="off"
                                       
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="pa_login_id"
                                       // onBlur={handleUsernameBlur1}
                                        className={errors.pa_login_id && touched.pa_login_id ? "input-error" : "mb-0"}
                                        ref={paLoginIdRef}
                                    />
                                    {errors.pa_login_id && touched.pa_login_id && <p className="error">{errors.pa_login_id}</p>}
                                </Col>

                                {/* --------------Student Password--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Password</h6>
                                    <Form.Control type="password"
                                        autoComplete="new-password"

                                        value={values.pa_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="pa_password"
                                        className={errors.pa_password && touched.pa_password ? "input-error" : "mb-0"}
                                    />
                                    {errors.pa_password && touched.pa_password && <p className="error">{errors.pa_password}</p>}
                                </Col>


                                {/* --------------Student Confirm Password--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Confirm Password</h6>
                                    <Form.Control type="password"
                                        autoComplete="new-password"

                                        value={values.pa_conform_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="pa_conform_password"
                                        className={errors.pa_conform_password && touched.pa_conform_password ? "input-error" : "mb-0"} />
                                    {errors.pa_conform_password && touched.pa_conform_password && <p className="error">{errors.pa_conform_password}</p>}
                                </Col>

                                {/* --------------Student Secret Question--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>
                                    <h6 className="form-lable">Secret Question</h6>
                                    <Form.Control type="text"
                                        value={values.pa_shp_question}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="pa_shp_question"
                                        className={errors.pa_shp_question && touched.pa_shp_question ? "input-error" : ""}
                                    />
                                    {errors.pa_shp_question && touched.pa_shp_question && <p className="error">{errors.pa_shp_question}</p>}
                                </Col>

                                {/* --------------Student Secrect Answer--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>
                                    <h6 className="form-lable">Secret Answer</h6>
                                    <Form.Control type="text"
                                        value={values.pa_shp_answer}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="pa_shp_answer"
                                        className={errors.pa_shp_answer && touched.pa_shp_answer ? "input-error" : ""}
                                    />
                                    {errors.pa_shp_answer && touched.pa_shp_answer && <p className="error">{errors.pa_shp_answer}</p>}
                                </Col>




                            </Row>
                        </div>
                    </Stack>

                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Contact Details</h6>
                            </div>

                            <Row>
                                {/* --------------Contact Address1--------------- */}
                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Address</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.par_address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_address"
                                        className={errors.par_address && touched.par_address ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.par_address && touched.par_address && <p className="error">{errors.par_address}</p>}
                                </Col>
                                {/* --------------Student city--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable ">City/Town</h6>
                                    <Form.Control type="text"
                                        value={values.par_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_city"
                                        className={errors.par_city && touched.par_city ? "input-error" : ""}
                                    />
                                    {errors.par_city && touched.par_city && <p className="error">{errors.par_city}</p>}
                                </Col>

                                {/* --------------Student state--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable ">County/Region/State</h6>
                                    <Form.Control type="text"
                                        value={values.par_state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_state"
                                        className={errors.par_state && touched.par_state ? "input-error" : ""}
                                    />
                                    {errors.par_state && touched.par_state && <p className="error">{errors.par_state}</p>}
                                </Col>

                                {/* --------------Student Country--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable ">Country</h6>
                                    <Select menuPlacement="auto" options={options_countries}
                                        name="par_country"
                                        value={options_countries.find(option => option.value && values.par_country && option.value.toString() === values.par_country.toString())}
                                        id="par_country"
                                        isDisabled={true}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "par_country",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, par_country: true });
                                        }}
                                        className={errors.par_country && touched.par_country ? "input-error" : ""}
                                    />
                                    {errors.par_country && touched.par_country && <p className="error">{errors.par_country}</p>}
                                </Col>


                                {/* --------------Student Postal Code--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Postal Code</h6>
                                    <Form.Control type="text"
                                        value={values.par_zip}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_zip"
                                        className={errors.par_zip && touched.par_zip ? "input-error" : ""}
                                    />
                                    {errors.par_zip && touched.par_zip && <p className="error">{errors.par_zip}</p>}
                                </Col>

                                {/* --------------How did you hear about us? --------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">How did you hear about us?</h6>
                                    <Select menuPlacement="auto" options={options_campaign}
                                        name="lead_ref_campaign"
                                        value={options_campaign.find(option => option.value && values.lead_ref_campaign && option.value.toString() === values.lead_ref_campaign.toString())}
                                        id="lead_ref_campaign"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "lead_ref_campaign",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, lead_ref_campaign: true });
                                        }}
                                        className={errors.lead_ref_campaign && touched.lead_ref_campaign ? "input-error" : ""}
                                    />
                                    {errors.lead_ref_campaign && touched.lead_ref_campaign && <p className="error">{errors.lead_ref_campaign}</p>}
                                </Col>

                                {/* --------------Student Timezone--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>
                                    <h6 className="form-lable required-field">Timezone</h6>
                                    <Select menuPlacement="auto" options={options_time_zone}
                                        name="st_timezone"
                                        isDisabled={true}
                                        value={options_time_zone.find(option => option.value && values.st_timezone && option.value.toString() == values.st_timezone.toString())}
                                        id="st_timezone"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "st_timezone",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, st_timezone: true });
                                        }}
                                        className={errors.st_timezone && touched.st_timezone ? "input-error" : ""}
                                    />
                                    {errors.st_timezone && touched.st_timezone && <p className="error">{errors.st_timezone}</p>}
                                </Col>


                            </Row>
                        </div>
                    </Stack>


                    {/* --------------Lead Details--------------- */}

                    <Stack className='mt-4' >
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Lead Details </h6>
                            </div>

                            <Row>

                                {/* --------------Details Preferred  Method Of Contact--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable"> Preferred Method Of Contact</h6>
                                    <Select menuPlacement="auto" options={options_preferedmethodofcontact}
                                        name="lead_pref_meth_contact"
                                        value={options_preferedmethodofcontact.find(option => option.value && values.lead_pref_meth_contact && option.value.toString() === values.lead_pref_meth_contact.toString())}
                                        id="lead_pref_meth_contact"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "lead_pref_meth_contact",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, lead_pref_meth_contact: true });
                                        }}
                                        className={errors.lead_pref_meth_contact && touched.lead_pref_meth_contact ? "input-error" : ""}
                                    />
                                    {errors.lead_pref_meth_contact && touched.lead_pref_meth_contact && <p className="error">{errors.lead_pref_meth_contact}</p>}

                                </Col>

                                {/* --------------Details Preferred  Time Of Contact--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable"> Preferred Time To Contact</h6>
                                    {/* <Form.Control type="time"

                                        value={values.lead_pref_tim_contact}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="lead_pref_tim_contact"
                                        className={errors.lead_pref_tim_contact && touched.lead_pref_tim_contact ? "input-error" : ""}
                                    /> */}

                                    <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                        <TimePicker 
                                        value={dayjs(values.lead_pref_tim_contact, 'HH:mm:ss')}
                                        onChange={(time) =>  {
                                        const event = {
                                        target: {
                                            name: "lead_pref_tim_contact",
                                            value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                        },
                                        };
                                        
                                        handleChange(event);
                                        
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, lead_pref_tim_contact: true });
                                        }}
                                        slotProps={{
                                            textField: { readOnly: true, size: 'small',
                                            id: 'lead_pref_tim_contact',
                                            },
                                        }}
                                        className={errors.lead_pref_tim_contact && touched.lead_pref_tim_contact ? "input-error w-100 h-auto" : "w-100 h-auto"}
                                        />
                                    
                                    </LocalizationProvider>
                                
                                    {errors.lead_pref_tim_contact && touched.lead_pref_tim_contact && <span className="error">{errors.lead_pref_tim_contact}</span>}
                                </Col>


                                {/* --------------Details Referral Source--------------- */}


                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Referral Source</h6>
                                    <Select menuPlacement="auto" options={options_refferalsource}
                                        name="lead_ref_source"
                                        value={options_refferalsource.find(option => option.value && values.lead_ref_source && option.value.toString() === values.lead_ref_source.toString())}
                                        id="lead_ref_source"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "lead_ref_source",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, lead_ref_source: true });
                                        }}
                                        className={errors.lead_ref_source && touched.lead_ref_source ? "input-error" : ""}
                                    />
                                    {errors.lead_ref_source && touched.lead_ref_source && <p className="error">{errors.lead_ref_source}</p>}
                                </Col>


                                {/* -------------- Details Status --------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable">Status Date</h6>
                                    {/* <Form.Control
                                        type="date"
                                        max={new Date().toISOString().split("T")[0]}
                                        value={values.ls_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ls_date"
                                        className={errors.ls_date && touched.ls_date ? "input-error" : ""}
                                    /> */}

                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.ls_date, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                const event = {
                                                target: {
                                                    name: "ls_date",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, ls_date: true });
                                            }}
                                            name="ls_date"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'ls_date',
                                                },
                                                }}
                                            className={errors.ls_date && touched.ls_date ? "input-error w-100" : "w-100"}
                                            minDate={dayjs()}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                                    {errors.ls_date && touched.ls_date && <span className="error">{errors.ls_date}</span>}
                                </Col>

                                {/* --------------Details Preferred  Method Of Contact--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable">Status</h6>
                                    <Select menuPlacement="auto" options={options_status}
                                        name="ls_status"
                                        value={options_status.find(option => option.value && values.ls_status && option.value.toString() === values.ls_status.toString())}
                                        id="ls_status"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "ls_status",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, ls_status: true });
                                        }}
                                        className={errors.ls_status && touched.ls_status ? "input-error" : ""}

                                    />
                                    {errors.ls_status && touched.ls_status && <p className="error">{errors.ls_status}</p>}

                                </Col>
                                {/* --------------description--------------- */}


                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable">Status Description</h6>
                                    <Form.Control
                                        as="textarea" rows={3}

                                        value={values.ls_description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ls_description"
                                        className={errors.ls_description && touched.ls_description ? "input-error control_textarea" : "control_textarea"}
                                    />
                                    {errors.ls_description && touched.ls_description && <p className="error">{errors.ls_description}</p>}
                                </Col>
                            </Row>
                        </div>
                    </Stack>


                    {/* --------------Additional Details--------------- */}
                    <Stack className='mt-4' >
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Initial Diagnostics Details </h6>
                            </div>

                            <Row>

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Initial Diagnostics Type</h6>
                                    <Select menuPlacement="auto" options={options_dig_type}
                                        name="la_type"
                                        value={options_dig_type.find(option => option.value && values.la_type && option.value.toString() === values.la_type.toString())}

                                        id="la_type"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "la_type",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, la_type: true });
                                        }}
                                        className={errors.la_type && touched.la_type ? "input-error" : "mb-3"}
                                    />
                                    {errors.la_type && touched.la_type && <p className="error">{errors.la_type}</p>}
                                </Col>

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Assessor Name</h6>
                                    <Select menuPlacement="auto" options={options_assessor_name}
                                        name="la_assessor"
                                        value={options_assessor_name.find(option => option.value && values.la_assessor && option.value.toString() === values.la_assessor.toString())}

                                        id="la_assessor"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "la_assessor",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, la_assessor: true });
                                        }}
                                        className={errors.la_assessor && touched.la_assessor ? "input-error" : "mb-3"}
                                    />
                                    {errors.la_assessor && touched.la_assessor && <p className="error">{errors.la_assessor}</p>}
                                </Col>

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Assessor Name</h6>
                                    <Form.Control type="text" 
                                    value={values.la_assessor}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="la_assessor"
                                    className={errors.la_assessor && touched.la_assessor ? "input-error" : ""}
                                     />
                                     {errors.la_assessor && touched.la_assessor && <p className="error">{errors.la_assessor}</p>}
                                </Col> */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Test Duration</h6>
                                    <Select menuPlacement="auto" options={options_duration}
                                        name="la_duration"
                                        value={options_duration.find(option => option.value && values.la_duration && option.value.toString() == values.la_duration.toString())}

                                        id="la_duration"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "la_duration",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, la_duration: true });
                                        }}
                                        className={errors.la_duration && touched.la_duration ? "input-error" : "mb-3"}
                                    />
                                    {errors.la_duration && touched.la_duration && <p className="error">{errors.la_duration}</p>}
                                </Col>

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Result Outcome</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.la_outcome}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="la_outcome"
                                        className={errors.la_outcome && touched.la_outcome ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.la_outcome && touched.la_outcome && <p className="error">{errors.la_outcome}</p>}
                                </Col>

                                {/* --------------Additional Goal--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">What is your goal</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.la_goals}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="la_goals"
                                        className={errors.la_goals && touched.la_goals ? "input-error control_textarea" : "control_textarea mb-0"} />
                                    {errors.la_goals && touched.la_goals && <p className="error">{errors.la_goals}</p>}
                                </Col>

                                {/* --------------Additional Notes--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Initial Diagnostics Notes</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.la_notes}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="la_notes"
                                        className={errors.la_notes && touched.la_notes ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.la_notes && touched.la_notes && <p className="error">{errors.la_notes}</p>}
                                </Col>


                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Additional Details End--------------- */}


                    {/* --------------Payment Details--------------- */}
                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Payment </h6>
                            </div>


                            <Tab.Container defaultActiveKey="first">
                                <Row>
                                    <Col sm={12}>
                                        <Nav variant="pills" className="flex-row justify-content-center mb-3 text-dark outside mx-auto">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first" className="px-4 py-2 text-dark me-2" onClick={handleOnClick1}>Weekly</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second" className="px-4 py-2 text-dark" onClick={handleOnClick2}>Monthly</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <Form>


                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="3">
                                                            <h6 className='required-field'>Select day of Week</h6>
                                                        </Form.Label>
                                                        <Col sm="9">

                                                            <Select menuPlacement="auto" options={options_days}
                                                                name="sp_start_ofDay"
                                                                value={options_days.find(option => option.value && values.sp_start_ofDay && option.value.toString() === values.sp_start_ofDay.toString())}
                                                                id="sp_start_ofDay"
                                                                onChange={(selectedOption) => {
                                                                    const event = {
                                                                        target: {
                                                                            name: "sp_start_ofDay",
                                                                            value: selectedOption["value"],
                                                                        },
                                                                    };
                                                                    handleChange(event);
                                                                }}
                                                                onBlur={(event) => {
                                                                    handleBlur(event);
                                                                    setTouched({ ...touched, sp_start_ofDay: true });
                                                                }}
                                                                className={errors.sp_start_ofDay && touched.sp_start_ofDay ? "input-error" : ""}
                                                            />
                                                            {errors.sp_start_ofDay && touched.sp_start_ofDay && <span className="error">{errors.sp_start_ofDay}</span>}

                                                            <Col xs={12} md={4}>
                                                                {/* <Button variant="primary" className="mt-2 px-4 py2 btn1">Add Payment</Button> */}
                                                            </Col>
                                                        </Col>
                                                    </Form.Group>

                                                </Form>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="second">
                                                <Form>


                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="3">
                                                            <h6  className='required-field'>Select day of Month</h6>
                                                        </Form.Label>
                                                        <Col sm="9">

                                                            <Select menuPlacement="auto" options={options_months}
                                                                name="sp_start_ofDay_mon"
                                                                value={options_months.find(option => option.value && values.sp_start_ofDay_mon && option.value.toString() === values.sp_start_ofDay_mon.toString())}
                                                                id="sp_start_ofDay_mon"
                                                                onChange={(selectedOption) => {
                                                                    const event = {
                                                                        target: {
                                                                            name: "sp_start_ofDay_mon",
                                                                            value: selectedOption["value"],
                                                                        },
                                                                    };
                                                                    handleChange(event);
                                                                }}
                                                                onBlur={(event) => {
                                                                    handleBlur(event);
                                                                    setTouched({ ...touched, sp_start_ofDay_mon: true });
                                                                }}
                                                                className={errors.sp_start_ofDay_mon && touched.sp_start_ofDay_mon ? "input-error" : ""}
                                                            />
                                                            {errors.sp_start_ofDay_mon && touched.sp_start_ofDay_mon && <span className="error">{errors.sp_start_ofDay_mon}</span>}

                                                            <Col xs={12} md={8}>
                                                                {/* <Button variant="primary" className="mt-2 px-4 py2 btn1">Add Payment</Button> */}
                                                            </Col>
                                                        </Col>
                                                    </Form.Group>

                                                </Form>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>

                        </div>
                    </Stack>

                    {/* --------------Payment Details End--------------- */}

                    <Stack className="mt-4">
                        <div className="my-card p-4 bg-white">
                        <div className="section-heading border-bottom pb-3 mb-3">
                            <h6 className="mb-0">Payment Mode </h6>
                        </div>

                        <Row>
                            <Col sm={12}>
                            <Form>
                                <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    <h6 className="required-field">
                                    Select The Payment Mode
                                    </h6>
                                </Form.Label>
                                <Col sm="9">
                                    <Select
                                    menuPlacement="auto"
                                    options={options_paymentModes}
                                    name="paymentMode "
                                    value={options_paymentModes.find(
                                        (option) =>
                                        option.value &&
                                        values.paymentMode &&
                                        option.value.toString() ===
                                            values.paymentMode.toString()
                                    ) || ''}
                                    id="paymentMode"
                                    onChange={ async (selectedOption) => {

                                        let event = {
                                            target: {
                                                name: "paymentMode",
                                                value: '',
                                            },
                                        };
                                        // Find the selected payment mode details from PaymentModesData
                                        const selectedPaymentMode = PaymentModesData.find(
                                            (pm) => pm.pk_cpm_key === selectedOption["value"]
                                        );

                                        // Perform the checks based on pm_type
                                        if (selectedPaymentMode) {
                                            if (
                                                parseInt(selectedPaymentMode.pm_type) === 1 &&
                                                (selectedPaymentMode.stripe_account_amount === null || selectedPaymentMode.stripe_account_amount === '')
                                            ) {
                                                toast.error("Stripe Account & Fees cannot be empty for this payment mode.", {
                                                    autoClose: 3000, // 3 seconds in this example
                                                });
                                                
                                                 // Prevent selection if the condition is not met
                                                 event = {
                                                    target: {
                                                        name: "paymentMode",
                                                        value: '',
                                                    },
                                                };
                                                formik.setFieldValue('fk_pm_key', '');
                                            } else if (
                                                parseInt(selectedPaymentMode.pm_type) === 2 &&
                                                (selectedPaymentMode.cpa_amount === null || selectedPaymentMode.cpa_amount === '')
                                            ) {
                                                toast.error("Offline fees cannot be empty for this payment mode.", {
                                                    autoClose: 3000, // 3 seconds in this example
                                                });

                                                 // Prevent selection if the condition is not met
                                                 event = {
                                                    target: {
                                                        name: "paymentMode",
                                                        value: '',
                                                    },
                                                };
                                                formik.setFieldValue('fk_pm_key', '');
                                            }else{
                                                event = {
                                                    target: {
                                                        name: "paymentMode",
                                                        value: selectedOption["value"],
                                                    },
                                                };

                                                formik.setFieldValue('fk_pm_key', selectedPaymentMode.pk_pm_key);
                                                //formik.validateField('paymentModes');
                                            }
                                        }else{
                                            toast.error("Online & Offline fees cannot be empty for this payment mode.", {
                                                autoClose: 3000, // 3 seconds in this example
                                            });
                                             // Prevent selection if the condition is not met
                                             event = {
                                                target: {
                                                    name: "paymentMode",
                                                    value: '',
                                                },
                                            };
                                            formik.setFieldValue('fk_pm_key', '');
                                        }

                                       

                                         handleChange(event);
                                    }}
                                    onBlur={(event) => {
                                        handleBlur(event);
                                        setTouched({
                                        ...touched,
                                        paymentMode: true,
                                        });
                                    }}
                                    className={
                                        errors.paymentMode && touched.paymentMode
                                        ? "input-error"
                                        : ""
                                    }
                                    />
                                    {errors.paymentMode && touched.paymentMode && (
                                    <span className="error">{errors.paymentMode}</span>
                                    )}
                                </Col>
                                </Form.Group>
                            </Form>
                            </Col>
                        </Row>
                        </div>
                    </Stack>

                    <Stack gap={2} className="mt-4">
                        <div className="text-end">

                            <Button variant="contained" className="primary-gray me-2" onClick={onGroupContainerClick}>Cancel</Button>
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Save</Button>
                        </div>
                    </Stack>

                </div>

            </Form>
        </>
    );
}

export default AddStudent;