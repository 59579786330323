

/*
Purpose: list details of student session with use datatable
Designed by KTH
Date:24/08/2023 
Project: TCMS
*/

import { useState, useEffect } from "react";
import { useLocation, NavLink } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import {
    security_key, options_countries, BASE_URL
} from "../global";
import Moment from 'moment';
import axios from 'axios';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { Button as Buttonmui } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Select from 'react-select';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
function AdminReportStudentSession() {
    const location = useLocation();
    useEffect(() => {
      // Store the previous pathname in sessionStorage
      sessionStorage.setItem('previousPageAdminPathname', location.pathname);
    }, [location.pathname]);
    const [loading, setLoading] = useState(true);
    const [option_centre, setOptions] = useState([]);
    
   
    const [selectedMonth, setSelectedMonth] = useState(dayjs().format('YYYY-MM'));

    const handleMonthChange = (date) => {
        if (date) {
        setSelectedMonth(dayjs(date).format('YYYY-MM')); // Store the selected month in YYYY-MM format
        } else {
        setSelectedMonth(null); // Handle null cases if needed
        }
    };



    const [selectedCentres, setSelectedCentres] = useState([]);

  const handleCentreChange = (selectedOptions) => {
    // Extract values from selected options
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedCentres(values); // Store selected values
  };

    const [data, setItems] = useState([]);

    useEffect(() => {
        fetchData();
        fetchCentreData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getAdminScheduleSessionReports",
                centre: selectedCentres,
                Month: selectedMonth,
            });
          //console.log(response.data.data)
            if (response.data.success === true) {
                setRecords(response.data.data);
                setItems(response.data.data);
                setLoading(false);
            } else {
                //alert message provide
                setLoading(false);
            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }

        
    };
    const fetchCentreData = async () => {
      

        try {
            const response_centre = await axios.post(BASE_URL+'API/api_index.php', {
              security_key: security_key,
              mode: 'getAllCenterGroupByCentre'
            });
          
            if(response_centre.data.success===true){
                const formattedOptions = response_centre.data.data.map(option => ({
                    value: option.pk_cent_key,
                    label: option.cent_name
                  }));
                  // formattedOptions.unshift({
                  //   value: '',
                  //   label: '--Select--'
                  // });
                  setOptions(formattedOptions);
            }else{
              //alert message provide
              setLoading(false);
            }
            
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    //Api call End

    
    // Sample columns configuration
    const columns = [
        {

            headerName: 'Month',
            field: "Month",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.month == null || params.row.month == '' ? '' : Moment(params.row.month,'YYYY-MM').format('MMM YYYY')}`;
            },
        },
        {

            headerName: 'Centre',
            field: "centreName",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.centre_name == null || params.row.centre_name == '' ? '-' : params.row.centre_name}`;
            },
        },
        {

            headerName: 'Subject',
            field: "subject",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.subject == null || params.row.subject == '' ? '-' : params.row.subject}`;
            },
        },
        {

            headerName: 'Schedule Sessions',
            field: "count_session",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.count_session == null || params.row.count_session == '' ? '0' : params.row.count_session}`;
            },
        },

    ];



    const [records, setRecords] = useState(data);
    // function handleFilter(event) {
    //     const inputValue = event.target.value.trim().toLowerCase();
    //     const newData = data.filter(row => {
    //         return (
    //             (row.enrol_disc_reason == null || row.enrol_disc_reason == '' ? '-' : row.enrol_disc_reason).toLowerCase().includes(inputValue) ||
    //             (row.st_first_name == null || row.st_first_name == '' ? '-' : row.st_first_name + ' ' + row.st_surname).toLowerCase().includes(inputValue) ||
    //             (row.enrol_start_date == null ? '-' : Moment(row.enrol_start_date).format('DD-MM-YYYY')).toLowerCase().includes(inputValue) ||
    //             (row.sest_to_date == null ? '-' : Moment(row.sest_to_date).format('DD-MM-YYYY')).toLowerCase().includes(inputValue) ||
    //             (row.sest_duration == null || row.sest_duration == '' ? '-' : row.sest_duration).toLowerCase().includes(inputValue) ||
    //             (row.subject_name == null || row.subject_name == '' ? '-' : row.subject_name).toString().toLowerCase().includes(inputValue)
    //         );
    //     });
    //     setRecords(newData);
    // }

    // function handleClick(event) {
    //     event.preventDefault();
    //     console.info('You clicked a breadcrumb.');
    // }

    //Define the CSV data and filename
    // const csvData = records.map(row => ({
    //     'Unenrollment Reson': row.enrol_disc_reason == null || row.enrol_disc_reason == '' ? '-' : row.enrol_disc_reason,
    //     'Student Name': row.st_first_name == null || row.st_first_name == '' ? '-' : row.st_first_name + ' ' + row.st_surname,
    //     'Enroll From': row.enrol_start_date == null ? '-' : Moment(row.enrol_start_date).format('DD-MM-YYYY'),
    //     'Enroll To': row.sest_to_date == null ? '-' : Moment(row.sest_to_date).format('DD-MM-YYYY'),
    //     'Duration': row.sest_duration == null || row.sest_duration == '' ? '-' : row.sest_duration,
    //     'Subject': row.subject_name == null || row.subject_name == '' ? '-' : row.subject_name
    // }));
    // const csvFileName = 'unenrolled_report.csv';
    // const [IsType, SetType] = useState('');
    // const [IsCountry, SetCountry] = useState('');
    // const [IsCentre, SetCentre] = useState('');
    useEffect(() => {
        
        //fetchYearLevelData();
      }, [selectedMonth,selectedCentres]);


    const handleGenerateClick = () =>{
        fetchData();
    }
      

    return (
        <div className="">

            {/* --------------Page Start--------------- */}

            <div className="mb-3 d-flex justify-content-between align-items-center">
                <div>
                    <h5 className='page-title'> Schedule Sessions </h5>

                    <div role="presentation" >
                        <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                            <NavLink underline="hover" color="inherit" to='/admin/admin-list'> Home</NavLink>
                            <NavLink underline="hover" color="inherit" to='/admin/AdminReport'> Reports</NavLink>
                            <Typography color="text.primary">Schedule Sessions </Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className='d-flex align-items-center gap-2'>
                   

                </div>

            </div>
            {/* --------------Section Start--------------- */}

            <Card className='card my-card mb-3'>

                <Card.Body>

                    {/* -------------Content Start--------------- */}

                    <Row>
                        <Col xs="12">
                            <div className="">
                            <Row className=" mb-3">
                            <Col lg="4" md="4" sm="12" className='position-relative'>
                            <h6 className="form-label ">Month</h6>
                                <LocalizationProvider className={'form-control mb-0'} dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        views={['year', 'month']} // Only year and month views
                                        value={dayjs(selectedMonth, 'YYYY-MM')} // Controlled value
                                        onChange={handleMonthChange}
                                        renderInput={(params) => <TextField {...params} />}
                                        className="w-100 h-auto"
                                        slotProps={{
                                            textField: { size: 'small',
                                            id: 'month',
                                            },
                                        }}
                                        format="MM-YYYY" // Display format
                                    />
                                </LocalizationProvider>
                                    </Col>
                                   
                                    <Col lg="4" md="4" sm="12" className='position-relative'>
                                    <h6 className="form-label">Centres</h6>
                                    <Select
                                        menuPlacement="auto"
                                        options={option_centre}
                                        name="fk_cent_key"
                                        value={option_centre.filter(option => selectedCentres.includes(option.value))}
                                        id="fk_cent_key"
                                        isMulti
                                        onChange={handleCentreChange}
                                        onBlur={(event) => {
                                        // Handle blur event if needed
                                        }}
                                    />
                                    </Col>
                                    
                                    <Col lg="4" md="4" sm="12" className='position-relative'>
                                        <div className="d-flex align-items-end ps-4">
                                        <Buttonmui variant="outlined-red"  onClick={() => handleGenerateClick()}  className="primary-blue mt-4 p-4 my-auto" >
                                            Generate
                                        </Buttonmui>
                                        </div>
                                    </Col>
                                    
                                    
                                     </Row>
                                <Row className="justify-content-center">

                                    {/* --------------Table Content Start--------------- */}
                                    {/* <Col sm={12}>

                                        <div className="d-flex flex-wrap justify-content-between mb-3">
                                            <CSVLink
                                                data={csvData}
                                                filename={csvFileName}
                                                // className="btn btn-primary"
                                                target="_blank"
                                            >
                                                <i class="fa-solid fa-file-csv my-auto" style={{ fontSize: '24px' }}></i>
                                            </CSVLink>

                                            <div class="form-group has-search my-auto">
                                                <span class="fa fa-search form-control-feedback"></span>
                                                <input type="text" class="form-control mb-0" onChange={handleFilter} />
                                            </div>


                                        </div>

                                    </Col> */}
                                    {/* --------------Table Content Start--------------- */}



                                    {/* --------------Table Content Start--------------- */}
                                    {/* <Col sm={12}>

                                        <DataTable
                                            progressPending={loading}
                                            columns={columns}
                                            data={records}
                                            fixedHeader
                                            pagination
                                            customStyles={customeStyle}
                                            className="cursor-pointer  list-cus-h"
                                        />

                                    </Col> */}

                                    <Box
                                        sx={{
                                            height: "fit-content",
                                            width: "100%",
                                            "& .actions": {
                                                color: "text.secondary",
                                            },
                                            "& .textPrimary": {
                                                color: "text.primary",
                                            },
                                            "& .super-app-theme--header": {
                                                backgroundColor: "#f4f4f4",
                                            },
                                            "& .MuiDataGrid-row": {
                                                border: '1px solid #eff0f2'
                                            },
                                            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                                                outline: "none !important",
                                            },
                                            "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                                            {
                                                outline: "none !important",
                                            },
                                        }}
                                        style={{ height: '100%', width: "100%" }}
                                    >
                                       <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                            //sx={{ border: "none", m: 2 }}
                                            autoHeight
                                            slots={{
                                                loadingOverlay: LinearProgress,
                                                //noRowsOverlay: CustomNoRowsOverlay,
                                                toolbar: GridToolbar,
                                                // toolbar: EditToolbar,
                                            }}
                                            slotProps={{
                                                toolbar: {
                                                    csvOptions: { fileName: "EnrolmentReport" },
                                                    printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "EnrolmentReport" },
                                                    records,
                                                    showQuickFilter: true
                                                },


                                            }}
                                            initialState={{
                                                ...records.initialState,
                                                pagination: { paginationModel: { pageSize: 10 } },
                                            }}
                                            showQuickFilter={true}

                                            pageSizeOptions={[10, 25, 50, 100]}

                                            loading={loading}
                                            rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                            columns={columns}
                                            rowHeight={60} density="compact"
                                            disableRowSelectionOnClick
                                            showCellVerticalBorder={true}
                                            showColumnVerticalBorder={true}

                                        />

                                    </Box>
                                    {/* --------------Table Content Start--------------- */}

                                </Row>

                            </div>
                        </Col>
                    </Row>

                    {/* --------------Content End--------------- */}


                </Card.Body>

            </Card>

            {/* --------------Section End--------------- */}




            {/* --------------Page End--------------- */}

        </div>
    );
}

export default AdminReportStudentSession;